import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DestroyableComponent } from '@core/destroyable';
import { takeUntil } from 'rxjs/operators';
import { GlobalSettingsModel } from '@models/global-settings.model';

@Injectable()
export class GlobalSettingsService extends DestroyableComponent {
	constructor(private http: HttpClient) {
		super();
	}

	Get(): Promise<GlobalSettingsModel> {
		return new Promise<GlobalSettingsModel>((resolve, reject) => {
			this.http
				.get<GlobalSettingsModel>('global-settings')
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error)
				);
		});
	}

	Save(settings: GlobalSettingsModel): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			this.http
				.post<boolean>('global-settings', settings)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error)
				);
		});
	}
}
