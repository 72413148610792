import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ImageType } from '@models/image-type.model';
import { BaseComponent } from '@core/base.component';
import { Image } from '@models/image.model';
import { of } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { ImageService } from '@services/image.service';
import { ImageUploadRequest } from '@models/image-upload-request.model';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'hubbs-image-upload-modal',
	templateUrl: './image-upload-modal.component.html',
})
export class ImageUploadModalComponent extends BaseComponent {
	@ViewChild('modalContent', { static: true }) protected modalContent: any;
	
	@Input() imageType: ImageType;
	@Output() imageUploaded: EventEmitter<Image> = new EventEmitter();
	
	protected modalOptions: NgbModalOptions = {
		ariaLabelledBy: 'title',
		keyboard: false,
		backdrop: 'static',
		size: 'md',
		centered: true
	};

	protected modalRef: NgbModalRef;
	
	constructor(private modalService: NgbModal, private imageService: ImageService) {
		super();
	}
	
	open(): void {
		this.modalRef = this.modalService.open(this.modalContent, this.modalOptions);

		this.modalRef.result.then(
			() => null,
			() => null
		);
	}
	
	close(): void {
		this.modalRef.close();
	}

	fileSelected(event, fileInput): void {
		if (!event.target.files[0]) {
			return;
		}

		Promise.all([
			this.processImage<Image>(event.target.files[0]).then((imageResponse: Image) => {
				this.imageUploaded.emit(imageResponse);
			}),
			of(null).pipe(takeUntil(this.ngUnsubscribe), delay(500)).toPromise(),
		]).then(() => {
			if (fileInput?.value != null) {
				fileInput.value = '';
			}

			this.close();
		});
	}
	
	private async processImage<T>(file: File): Promise<T> {
		return new Promise<T>((resolve) => {
			const reader = new FileReader();
			reader.onload = () => {
				const uploadRequest: ImageUploadRequest = {
					base64: window.btoa(reader.result as string),
					fileName: file.name,
				};

				this.imageService.Upload<T>(uploadRequest).then((response: T) => {
					resolve(response);
				});
			};

			reader.readAsBinaryString(file);
		});
	}
}
