import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
	name: 'string',
	pure: true,
})
export class StringPipe implements PipeTransform {
	transform(value: any): string {
		if (value == null) {
			return value;
		}

		return value.toString();
	}
}
