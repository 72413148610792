import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { DestroyableComponent } from '@core/destroyable';
import { DashboardType } from '@models/dashboard-type.model';
import { takeUntil } from 'rxjs/operators';
import {ReportOverviewModel} from "@models/report-overview.model";

@Injectable()
export class ReportService extends DestroyableComponent {
	public static RefreshDashboard: EventEmitter<void> = new EventEmitter<void>();
	
	constructor(private http: HttpClient) {
		super();
	}

	GetReport(): Promise<ReportOverviewModel> {
		return new Promise<ReportOverviewModel>((resolve, reject) => {
			this.http
				.get<ReportOverviewModel>(`report`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error)
				);
		});
	}
}
