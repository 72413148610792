import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SortableTableHeaderComponent } from './sortable-table-header.component';
import { PipeModule } from '@shared/pipes/pipe.module';

@NgModule({
	declarations: [SortableTableHeaderComponent],
	exports: [SortableTableHeaderComponent],
	imports: [CommonModule, PipeModule],
})
export class SortableTableHeaderModule {}
