import { Component, Input, ViewChild, ElementRef, OnInit, HostListener } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { DestroyableComponent } from '@core/destroyable';
import { NgbDateStruct, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '@services/common.service';
import { LanguageService } from '@services/language.service';
import { TranslationService } from '@services/translation.service';
import { ValidationService } from '@services/validation.service';
import { format, parse } from 'date-fns';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'hubbs-input-date',
	templateUrl: 'input-date.component.html',
	styleUrls: ['input-date.component.scss'],
})
export class InputDateComponent extends DestroyableComponent implements OnInit {
	@ViewChild('input', { static: true }) inputField: ElementRef;
	@ViewChild('popover', { static: true }) popover: NgbPopover;

	@Input() label: string;
	@Input() name: string;
	@Input() placeholder: string;
	@Input() control: AbstractControl;
	@Input() showTitle: boolean = true;
	@Input() readonly: boolean = false;
	
	

	selectedJSDate: Date;
	selectedDate: NgbDateStruct = {
		day: 0,
		month: 0,
		year: 0,
	};
	dateSelected: boolean = false;
	
	isInteractingWithPopover: boolean;

	@Input() translate: boolean = true;

	labels: { [key: string]: string } = {};

	randomId: string;

	constructor(private translationService: TranslationService, private elementRef: ElementRef) {
		super();

		LanguageService.LanguageChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.setLabels();
		});

		this.randomId = CommonService.GenerateGuid();
	}
	
	ngOnInit(): void {
		this.setLabels();
		if (this.control != null) {
			if (this.control.validator != null) {
				this.control.setValidators([this.control.validator, ValidationService.dateValidator]);
			}
			else {
				this.control.setValidators([ValidationService.dateValidator]);
			}
			this.selectedJSDate = this.control.value != null ? parse(this.control.value, 'dd-MM-yyyy', new Date()) : null;

			this.control.setValue(this.selectedJSDate ? format(this.selectedJSDate, 'dd-MM-yyyy') : null, { emitEvent: false, onlySelf: true });
			this.control.statusChanges.pipe(takeUntil(this.ngUnsubscribe), filter(() => this.control.disabled)).subscribe(() => {
				this.popover.close();
				this.isInteractingWithPopover = false;
			});

			this.control.valueChanges.pipe(takeUntil(this.ngUnsubscribe), filter(() => !this.dateSelected)).subscribe(() => {
				this.formatDate(this.control.value);
			});
		}
	}

	selectDate(value: NgbDateStruct): void {
		this.selectedDate = value;
		this.selectedJSDate = parse(`${ this.selectedDate.day.toString().padStart(2, '0') }-${ this.selectedDate.month.toString().padStart(2, '0') }-${ this.selectedDate.year.toString().padStart(4, '0') }`, 'dd-MM-yyyy', new Date());
		
		this.dateSelected = true;
		this.control.setValue(this.selectedJSDate ? format(this.selectedJSDate, 'dd-MM-yyyy') : null);
		this.dateSelected = false;

		this.popover.close();
	}

	closePopover(force: boolean = false): void {
		if (force) {
			this.isInteractingWithPopover = false;
		}
		
		if (this.popover.isOpen && !this.isInteractingWithPopover) {
			this.popover.close();
		} else {
			this.isInteractingWithPopover = false;
		}
	}
	
	onKeyUp(event: any): void {
		this.formatDate(event.target.value);
	}
	
	@HostListener('document:click', ['$event'])
	onClickOutside(event) {
		if (!this.elementRef.nativeElement.contains(event.target)) {
			this.closePopover(true);
		}
	}
	
	private formatDate(value: string): void {
		let date: string;
		let validDate = false;

		if (value && value.match(/^([0][0-9]|[1][0-9]|[2][0-9]|[3][0-1])-([0][0-9]|[1][0-2])-[0-9]{4}$/)) {
			date = value.substring(0, 2) + '-' + value.substring(3, 5) + '-' + value.substring(6, 10);
			validDate = true;
		}

		if (validDate) {
			if (this.control.value !== date) {
				this.control.setValue(date);
			}

			this.selectedJSDate = parse(this.control.value, 'dd-MM-yyyy', new Date());
			this.selectedDate = {
				day: this.selectedJSDate.getDate(),
				month: this.selectedJSDate.getMonth() + 1,
				year: this.selectedJSDate.getFullYear()
			};
		}
	}
	
	private setLabels(): void {
		if (this.translate) {
			this.labels.label = this.translationService.GetTranslation(this.label);
			this.labels.placeholder = this.translationService.GetTranslation(this.placeholder);
			this.labels.defaultPlaceholder = this.translationService.GetTranslation('date_input_default_placeholder');
		} else {
			this.labels.label = this.label;
			this.labels.placeholder = this.placeholder;
			this.labels.defaultPlaceholder = this.translationService.GetTranslation('date_input_default_placeholder');
		}
	}

	get validator() {
	
		if (!this.control.validator) {
			return false;
		}
		const validator = this.control.validator({} as AbstractControl);
		if (validator && validator.required) {
			return true;
		}

		return false;
	}
}
