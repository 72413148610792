<div class="form-floating" [class.mb-3]="control.enabled" [ngbPopover]="calendarContent" [disablePopover]="control?.disabled" placement="bottom-right" popoverClass="date-popover" [autoClose]="false" #popover="ngbPopover" (click)="isInteractingWithPopover = true">
	<input
		#input
		[id]="name + randomId"
		type="text"
		class="form-control form-control-lg"
		(keyup)="onKeyUp($event)"
		[placeholder]="placeholder ? labels.placeholder : labels.defaultPlaceholder"
		[formControl]="control"
		[ngClass]="{
			'is-invalid border-danger': control.invalid && (control.dirty || control.touched)
		}"
	/>
	<ng-template #calendarContent>
		<div (click)="isInteractingWithPopover = true">
			<hubbs-datepicker (selectDate)="selectDate($event)" [selectedDate]="control.value ? selectedDate : null"></hubbs-datepicker>
		</div>
	</ng-template>
	<label [for]="name + randomId">
		{{ labels.label }}
		<span *ngIf="validator && !control.disabled"> *</span>
	</label>
	<hubbs-error-messages [control]="control" [label]="labels.label"></hubbs-error-messages>
</div>