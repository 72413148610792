import { Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { BaseComponent } from '@core/base.component';
import { SortDirection } from '@models/sort-direction.model';

@Component({
	selector: 'hubbs-sortable-table-header, th[sortable]',
	template: `
			{{ label | translate: activeLanguage }}
			<ng-container *ngIf="name === currentOrder">
				<i class="ml-3 fas" [ngClass]="sortDirection === SortDirection.asc ? 'fa-sort-up align-text-bottom' : 'fa-sort-down align-text-top'"></i>
			</ng-container>
	`,
})
export class SortableTableHeaderComponent extends BaseComponent {
	SortDirection: any = SortDirection;

	@Input() label: string;
	@Input() name: string;
	@Input() currentOrder: string;
	@Input() sortDirection: SortDirection;
	@Output() sort: EventEmitter<void> = new EventEmitter();
	
	@HostBinding('class') class: string = 'cursor-pointer';
	@HostListener('click', ['$event.target'])
	onClick() {
		this.sort.emit();
	}
}
