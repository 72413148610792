import { EventEmitter, Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { filter, takeUntil } from 'rxjs/operators';
import { DestroyableComponent } from '@core/destroyable';
import { WindowSize } from '@models/window-size.model';

@Injectable()
export class BreakpointService extends DestroyableComponent {
	public static CurrentWindowSize: WindowSize;
	public static WindowSizeChanged: EventEmitter<void> = new EventEmitter();
	private static initialized: boolean;

	private supportedBreakPoints: any[] = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];
	private breakPoints: { [key: string]: string } = {
		xs: '(max-width: 575.98px)',
		sm: '(min-width: 576px) and (max-width: 767.98px)',
		md: '(min-width: 768px) and (max-width: 991.98px)',
		lg: '(min-width: 992px) and (max-width: 1199.98px)',
		xl: '(min-width: 1200px) and (max-width: 1399.98px)',
		xxl: '(min-width: 1400px)',
	};

	constructor(private breakpointObserver: BreakpointObserver) {
		super();
	}

	public Initialize() {
		if (!BreakpointService.initialized) {
			BreakpointService.initialized = true;

			for (const breakpoint of this.supportedBreakPoints) {
				this.observeChanges(breakpoint);
			}
		}
	}

	private observeChanges(size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'): void {
		this.breakpointObserver
			.observe(this.breakPoints[size])
			.pipe(
				takeUntil(this.ngUnsubscribe),
				filter((state) => state.matches)
			)
			.subscribe(() => {
				BreakpointService.CurrentWindowSize = WindowSize[size];
				BreakpointService.WindowSizeChanged.emit();
			});
	}
}
