import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DestroyableComponent } from '@core/destroyable';
import { DialogContent } from '@models/dialog-content.model';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '@services/authentication.service';
import { TranslationService } from '@services/translation.service';

@Component({
	selector: 'hubbs-token-expiration-modal',
	template: `
<!--		<ng-template #content let-modal>-->
<!--			<div class="modal-header d-flex align-items-center">-->
<!--				<h5 class="title m-0">{{ dialogContent.title }}</h5>-->
<!--			</div>-->
<!--			<div class="modal-body px-3">-->
<!--				<p class="mb-3" [innerHTML]="dialogContent.text"></p>-->
<!--			</div>-->
<!--			<div class="modal-footer p-3">-->
<!--				<div class="w-100 m-0 d-flex justify-content-between">-->
<!--					<button type="button" (click)="signOut()" class="btn btn-hubbs-cancel float-start  ml-0">-->
<!--						<span>-->
<!--							{{ dialogContent.declineButtonText }}-->
<!--						</span>-->
<!--					</button>-->
<!--					<button type="button" (click)="renew()" class="btn btn-hubbs-red float-end">-->
<!--						<span>-->
<!--							{{ dialogContent.acceptButtonText }}-->
<!--						</span>-->
<!--					</button>-->
<!--				</div>-->
<!--			</div>-->
<!--		</ng-template>-->
	`,
	styles: [],
})
export class TokenExpirationModalComponent extends DestroyableComponent implements OnDestroy {
	@ViewChild('content', { static: false }) protected content: any;

	protected modalOptions: NgbModalOptions = {};
	protected modalReference: NgbModalRef;

	dialogContent: DialogContent;
	minTillExp: number;
	logoutTimeout: ReturnType<typeof setTimeout>;
	tokenCheckInterval: ReturnType<typeof setInterval>;

	constructor(private modalService: NgbModal, private authenticationService: AuthenticationService, private translationService: TranslationService) {
		super();
	}

	// ngOnInit(): void {
	// 	// this.tokenCheckInterval = setInterval(() => this.checkToken(), 2000);
	// }

	ngOnDestroy(): void {
		clearInterval(this.tokenCheckInterval);
	}

	// checkToken() {
	// 	var token = AuthenticationService.TokenData;
	// 	var remainingTime = token.exp - Date.now();
	// 	if (remainingTime < 0) {
	// 		this.signOut();
	// 	} else if (remainingTime <= 600000) {
	// 		debugger;
	// 		this.authenticationService.RenewToken();
	// 	}
	// 	// } && !this.modalService.hasOpenModals()) {
	// 	// 	this.showConfirmation({
	// 	// 		centered: true,
	// 	// 		text: this.translationService.GetTranslation('token_expiration_modal_content'),
	// 	// 		title: this.translationService.GetTranslation('token_expiration_modal_title'),
	// 	// 		acceptButtonText: this.translationService.GetTranslation('token_expiration_modal_renew'),
	// 	// 		declineButtonText: this.translationService.GetTranslation('token_expiration_modal_signout'),
	// 	// 		size: 'md',
	// 	// 	});
	// 	// }
	// }

	showConfirmation(dialogContent: DialogContent): Promise<boolean> {
		this.dialogContent = dialogContent;

		this.modalOptions.ariaLabelledBy = 'title';
		this.modalOptions.keyboard = false;
		this.modalOptions.backdrop = 'static';
		this.modalOptions.size = dialogContent.size;
		this.modalOptions.windowClass = 'modal-confirm';
		this.modalOptions.centered = dialogContent.centered;

		this.modalReference = this.modalService.open(this.content, this.modalOptions);
		return this.modalReference.result;
	}

	public signOut(): void {
		this.authenticationService.SignOut();
		this.modalReference.close(null);
	}

	// public renew(): void {
	// 	this.authenticationService.RenewToken();
	// 	this.modalReference.close(true);
	// }
}
