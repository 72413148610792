import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {DestroyableComponent} from '@core/destroyable';
import {CommonService} from '@services/common.service';
import {LanguageService} from '@services/language.service';
import {TranslationService} from '@services/translation.service';
import {of} from 'rxjs';
import {delay, takeUntil} from 'rxjs/operators';
import { NpnSliderComponent } from 'npn-slider';

@Component({
	selector: 'hubbs-input-multi-range',
	template: `
		<div class="form-floating form-range" [class.mb-3]="control.enabled">
			<npn-slider 
				#input 
				[id]="name + randomId" 
				(onChange)="onSliderChange($event)" 
				[min]="min" 
				[max]="max"
				[step]="1"
				[minSelected]="minSelected"
				[maxSelected]="maxSelected"
				[showStepIndicator]="true"
				class="form-control form-control-lg"
			></npn-slider>
			<label [for]="name + randomId">
				{{ labels.label }}
				<span *ngIf="validator && !control.disabled"> *</span>
			</label>
			<hubbs-error-messages [control]="control" [label]="labels.label"></hubbs-error-messages>
		</div>
	`,
	styles: [],
})
export class InputMultiRangeComponent extends DestroyableComponent implements OnInit {
	@ViewChild('input', { static: true }) input: NpnSliderComponent;

	@Input() label: string;
	@Input() name: string;
	@Input() min: number = 0;
	@Input() max: number = 24;
	@Input() placeholder: string;
	@Input() type: string = 'text';
	@Input() control: AbstractControl;
	@Input() autocomplete: string = 'off';

	minSelected: number;
	maxSelected: number;

	labels: { [key: string]: string } = {};
	randomId: string;
	isInitial: boolean = true;

	constructor(private translationService: TranslationService, private cdRef: ChangeDetectorRef) {
		super();

		LanguageService.LanguageChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.setLabels();
		});
		this.randomId = CommonService.GenerateGuid();
	}

	ngOnInit() {
		this.setLabels();

		if (this.control?.value) {
			this.minSelected = this.control.value[0];
			this.maxSelected = this.control.value[1];
		} else {
			this.minSelected = this.min;
			this.maxSelected = this.max;
		}
	}

	onSliderChange(selectedValues: number[]) {
		this.control.setValue(selectedValues, { emitEvent : !this.isInitial} );
		this.isInitial = false;
	}

	private setLabels(): void {
		this.labels.label = this.translationService.GetTranslation(this.label);
		this.labels.placeholder = this.translationService.GetTranslation(this.placeholder);
	}

	get validator() {
		if (!this.control.validator) {
			return false;
		}
		const validator = this.control.validator({} as AbstractControl);
		if (validator && validator.required) {
			return true;
		}

		return false;
	}
}
