import { NgModule } from '@angular/core';
import { LoadingScreenComponent } from './loading-screen.component';
import { CommonModule } from '@angular/common';

@NgModule({
	declarations: [LoadingScreenComponent],
	exports: [LoadingScreenComponent],
	imports: [CommonModule],
})
export class LoadingScreenModule {}
