import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ErrorMessagesModule } from '../error-messages/error-messages.module';
import { ImageUploadModalComponent } from '@shared/components/image-upload-modal/image-upload-modal.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { PipeModule } from '@shared/pipes/pipe.module';

@NgModule({
	declarations: [ImageUploadModalComponent],
	exports: [ImageUploadModalComponent],
	imports: [CommonModule, FormsModule, NgbModalModule, ReactiveFormsModule, ErrorMessagesModule, PipeModule],
})
export class ImageUploadModalModule {}
