import { NgModule } from '@angular/core';
import { TranslatePipe } from '@shared/pipes/translate.pipe';
import { ReplacePipe } from '@shared/pipes/replace.pipe';
import { ObjectKeysPipe } from '@shared/pipes/object-keys.pipe';
import { StringPipe } from '@shared/pipes/string.pipe';

@NgModule({
	declarations: [ObjectKeysPipe, ReplacePipe, StringPipe, TranslatePipe],
	exports: [ObjectKeysPipe, ReplacePipe, StringPipe, TranslatePipe],
})
export class PipeModule {
}
