import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputPasswordComponent } from './input-password.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ErrorMessagesModule } from '../error-messages/error-messages.module';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
	declarations: [InputPasswordComponent],
	exports: [InputPasswordComponent],
	imports: [CommonModule, FormsModule, ReactiveFormsModule, ErrorMessagesModule, NgbPopoverModule],
})
export class InputPasswordModule {}
