import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {DestroyableComponent} from '@core/destroyable';
import {takeUntil} from 'rxjs/operators';
import {CommonService} from './common.service';
import {ContractTableFilter} from "@models/contract-table-filter.model";
import { Contract } from '@models/contract.model';
import { TablePageResult } from '@models/table-page-result.model';
import { ContractOverviewItem } from '@models/contract-overview-item.model';
import { Company } from '@models/company.model';
import { RelationTableFilter } from '@models/relation-table-filter.model';
import { RelationExportType } from '@models/relation-export-type.model';
import { saveAs } from 'file-saver';

@Injectable()
export class ContractService extends DestroyableComponent {
	constructor(private http: HttpClient) {
		super();
	}

	GetAll(filters: ContractTableFilter): Promise<TablePageResult<ContractOverviewItem>> {
		return new Promise<TablePageResult<ContractOverviewItem>>((resolve, reject) => {
			const filterParams = CommonService.GenerateParams(filters);
			this.http
				.get<TablePageResult<ContractOverviewItem>>(`contracts?${filterParams}`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error)
				);
		});
	}
	
	GetForRelation(relationGuid: string): Promise<ContractOverviewItem[]> {
		return new Promise<ContractOverviewItem[]>((resolve, reject) => {
			this.http
				.get<ContractOverviewItem[]>(`relations/${relationGuid}/contracts`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error)
				);
		});
	}
	
	GetForCompany(companyGuid: string, expiredContracts: boolean): Promise<ContractOverviewItem[]> {
		return new Promise<ContractOverviewItem[]>((resolve, reject) => {
			this.http
				.get<ContractOverviewItem[]>(`company/${companyGuid}/contracts/${expiredContracts}`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error)
				);
		});
	}

	Get(guid: string): Promise<Contract> {
		return new Promise<Contract>((resolve, reject) => {
			this.http
				.get<Contract>(`contracts/${guid}`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error)
				);
		});
	}

	Delete(guid: string): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			this.http
				.delete<void>(`contracts/${guid}`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					() => resolve(),
					(error) => reject(error)
				);
		});
	}

	Save(contract: Contract): Promise<string> {
		return new Promise<string>((resolve, reject) => {
			this.http
				.post<string>(`contracts`, contract)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error)
				);
		});
	}

	GenerateExcel(filters: ContractTableFilter): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			const filterParams = CommonService.GenerateParams(filters);
			this.http.get(`contracts/generate-excel?${ filterParams }`, { observe: 'response', responseType: 'blob', headers: { 'X-No-Redirect': 'true' } })
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(response) => {
						const blob = new Blob([response.body],
							{type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
						const contentDisposition = response.headers.get('content-disposition');
						const fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim().replace(/"/g, '');
						saveAs(blob, fileName);
						resolve();
					},
					(error) => reject(error),
				);
		});
	}
}
