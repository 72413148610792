import { Component, ViewChild } from '@angular/core';
import { BaseComponent } from '@core/base.component';
import { BreakpointService } from '@services/breakpoint.service';
import { ConfirmationService } from '@services/confirmation.service';
import { LoadingService } from '@services/loading.service';
import { TranslationService } from '@services/translation.service';
import { ConfirmModalComponent } from '@shared/components/confirm-modal/confirm-modal.component';
import { LoadingScreenComponent } from '@shared/components/loading-screen/loading-screen.component';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'hubbs-root',
	template: `
		<section>
			<hubbs-loading-screen #loadingScreen></hubbs-loading-screen>
			<hubbs-confirm-modal #confirmmodal></hubbs-confirm-modal>
			<router-outlet></router-outlet>
		</section>
	`,
	styles: [],
})
export class AppComponent extends BaseComponent {
	@ViewChild('loadingScreen', { static: true }) loadingScreen: LoadingScreenComponent;
	@ViewChild('confirmmodal', { static: true }) confirmmodal: ConfirmModalComponent;

	constructor(private breakpointService: BreakpointService, private loadingService: LoadingService, private translationService: TranslationService, private confirmationService: ConfirmationService) {
		super();

		this.breakpointService.Initialize();

		this.loadingService.loadingEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
			next: (data) => {
				if (data.hasOwnProperty('key')) {
					this.loadingScreen.title = this.translationService.GetTranslation(data.key);
				}
				if (data.hasOwnProperty('show')) {
					this.loadingScreen.isLoading$.next(data.show);
				}
			},
		});

		this.confirmationService.showConfirmation.pipe(takeUntil(this.ngUnsubscribe)).subscribe((dialogContent: any) => {
			this.confirmmodal.showConfirmation(dialogContent).then((result) => {
				this.confirmationService.confirmationResult.emit(result);
			});
		});
	}
}
