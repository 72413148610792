import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { GenerateTokenRequest } from '@models/generate-token-request.model';
import { TokenType } from '@models/token-type.model';
import { Token } from '@models/token.model';
import { UserStatus } from '@models/user-status.model';
import { AuthenticationService } from '@services/authentication.service';
import { CommonService } from '@services/common.service';
import { TokenService } from '@services/token.service';
import { Observable } from 'rxjs';

@Injectable()
export class UnAuthGuard implements CanActivate {
	UserStatus: UserStatus;
	constructor(private router: Router, private tokenService: TokenService) {}

	canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> | Promise<boolean> {
		return AuthenticationService.HasValidToken().then(
			(result) => {
				if (result) {
					this.router.navigate(['/auth/dashboard']);
					return false;
				}

				return true;
			},
			() => false
		);
	}
}
