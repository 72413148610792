import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DestroyableComponent } from '@core/destroyable';
import { takeUntil } from 'rxjs/operators';
import { RentCalculationModel } from '@models/rent-calculation.model';

@Injectable()
export class RentCalculationService extends DestroyableComponent {
	constructor(private http: HttpClient) {
		super();
	}

	Get(): Promise<RentCalculationModel[]> {
		return new Promise<RentCalculationModel[]>((resolve, reject) => {
			this.http
				.get<RentCalculationModel[]>(`rent-calculation`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error)
				);
		});
	}

	Save(calculations: RentCalculationModel[]): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			this.http
				.post<boolean>(`rent-calculation`, calculations)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error)
				);
		});
	}
}
