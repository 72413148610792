import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { DestroyableComponent } from '@core/destroyable';
import { NgSelectConfig } from '@ng-select/ng-select';
import { CommonService } from '@services/common.service';
import { LanguageService } from '@services/language.service';
import { TranslationService } from '@services/translation.service';
import { of } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'hubbs-input-select',
	template: `
		<div class="form-floating" [class.mb-3]="control.enabled">
			<ng-select
				#select
				[id]="name"
				[items]="options"
				[bindLabel]="value"
				[bindValue]="key"
				[multiple]="multiple"
				[placeholder]="labels.placeholder"
				[groupBy]="groupBy"
				[clearable]="clearable"
				[closeOnSelect]="!multiple"
				[searchable]="searchable"
				class="form-control form-control-lg"
				[formControl]="control"
				[ngClass]="{
					'is-invalid border-danger': control.invalid && (control.dirty || control.touched)
				}"
			>
				<ng-template ng-header-tmp *ngIf="multiple && showSelectAll">
					<div>
						<button class="btn btn-link" type="button"
								(click)="selectAll()">{{ labels.selectAllText }}</button>
						<button class="btn btn-link" type="button"
								(click)="clearAll()">{{ labels.deselectAllText }}</button>
					</div>
				</ng-template>
				<ng-template ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="multiple">
					<div class="ng-value" *ngFor="let item of items | slice: 0:maxVisibleItems">
						<span class="ng-value-icon left  {{item?.class}}" (click)="clear(item)" aria-hidden="true">×</span>
						<span class="ng-value-label  {{item?.class}}">{{ Translate(value !== undefined ? item[value] : item) }}</span>
					</div>
					<div class="ng-value" *ngIf="items.length - maxVisibleItems >= 1">
						<span
							class="ng-value-label">{{ (labels?.maxVisibleItemText ? labels?.maxVisibleItemText : '') | replace:'{0}':(items.length - maxVisibleItems | string) }}</span>
					</div>
				</ng-template>
				<ng-template ng-label-tmp let-item="item">
					<span class="{{item?.class}}">{{ Translate(item.value) }}</span>
				</ng-template>
				<ng-template ng-option-tmp let-item="item" let-index="index">
					<span class="{{item?.class}}">{{ Translate(item.value) }}</span>
				</ng-template>
			</ng-select>
			<label [for]="name + randomId">
				{{ labels.label }}
				<span *ngIf="validator && !control.disabled"> *</span>
			</label>
			<hubbs-error-messages [control]="control" [label]="labels.label"></hubbs-error-messages>
		</div>
	`,
	styles: [],
})
export class InputSelectComponent extends DestroyableComponent implements OnInit {
	@ViewChild('select', { static: true }) inputField: ElementRef;

	@Input() label: string;
	@Input() name: string;
	@Input() placeholder: string;

	@Input() focus: boolean;
	@Input() control: AbstractControl;

	@Input() options: any[];
	@Input() key: string = 'key';
	@Input() value: string = 'value';
	@Input() groupBy: string;

	@Input() multiple: boolean = false;
	@Input() maxVisibleItems: number = 2;
	@Input() clearable: boolean = false;
	@Input() searchable: boolean = true;
	@Input() showSelectAll: boolean = false;

	@Input() translate: boolean = false;

	labels: { [key: string]: string } = {};
	randomId: string;

	constructor(private config: NgSelectConfig, private translationService: TranslationService) {
		super();

		LanguageService.LanguageChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.setLabels();
		});
		this.randomId = CommonService.GenerateGuid();
	}

	ngOnInit() {
		this.setLabels();

		if (this.focus) {
			of(null)
				.pipe(takeUntil(this.ngUnsubscribe), delay(1))
				.subscribe(() => {
					if (!this.inputField?.nativeElement) {
						return;
					}

					this.inputField.nativeElement.focus();
				});
		}
	}

	selectAll() {
		this.control.setValue(this.options.map((x) => x[this.key]));
	}

	clearAll() {
		this.control.setValue([]);
	}

	private setLabels(): void {
		this.config.loadingText = this.translationService.GetTranslation('select_input_loading');
		this.config.clearAllText = this.translationService.GetTranslation('select_input_clearall');
		this.config.notFoundText = this.translationService.GetTranslation('select_input_notfound');
		this.config.typeToSearchText = this.translationService.GetTranslation('select_input_typetosearch');
		this.config.addTagText = this.translationService.GetTranslation('select_input_addtag');

		this.labels.selectAllText = this.translationService.GetTranslation('select_input_selectall');
		this.labels.deselectAllText = this.translationService.GetTranslation('select_input_deselectall');
		this.labels.maxVisibleItemText = this.translationService.GetTranslation('select_input_maxvisibleitemtext');

		this.labels.label = this.translationService.GetTranslation(this.label);
		this.labels.placeholder = this.translationService.GetTranslation(this.placeholder);
	}

	public Translate(key: string) {
		if (this.translate) {
			return this.translationService.GetTranslation(key);
		} else {
			return key;
		}
	}

	get validator() {
		if (!this.control.validator) {
			return false;
		}
		const validator = this.control.validator({} as AbstractControl);
		if (validator && validator.required) {
			return true;
		}

		return false;
	}
}
