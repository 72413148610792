import { PipeTransform, Pipe } from '@angular/core';
import { TranslationService } from '@services/translation.service';
@Pipe({
	name: 'replace',
	pure: true,
})
export class ReplacePipe implements PipeTransform {
	transform(value: string, valueToReplace: string, newValue: string): string {
		if (value == null || valueToReplace == null) {
			return value;
		}
		
		return value.replace(valueToReplace, newValue);
	}
}
