import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputSelectAsyncComponent } from './input-select-async.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ErrorMessagesModule } from '../error-messages/error-messages.module';
import { PipeModule } from '@shared/pipes/pipe.module';

@NgModule({
	declarations: [InputSelectAsyncComponent],
	exports: [InputSelectAsyncComponent],
	imports: [CommonModule, NgSelectModule, FormsModule, ReactiveFormsModule, ErrorMessagesModule, PipeModule]
})
export class InputSelectAsyncModule {}
