import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DestroyableComponent } from '@core/destroyable';
import { CreateUser } from '@models/create-user.model';
import { UpdateUser } from '@models/update-user.model';
import { UserTableFilter } from '@models/user-table-filter.model';
import { map, takeUntil } from 'rxjs/operators';
import { CommonService } from './common.service';
import { NgOption } from '@ng-select/ng-select';
import { SelectOption } from '@models/select-option.model';
import { Observable } from 'rxjs';
import { User } from '@models/user.model';
import { TablePageResult } from '@models/table-page-result.model';

@Injectable()
export class UserService extends DestroyableComponent {
	constructor(private http: HttpClient) {
		super();
	}

	GetAll(filters: UserTableFilter): Promise<TablePageResult<User>> {
		return new Promise<TablePageResult<User>>((resolve, reject) => {
			const filterParams = CommonService.GenerateParams(filters);
			this.http
				.get<TablePageResult<User>>(`users?${filterParams}`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error)
				);
		});
	}

	Get(userGuid: string): Promise<User> {
		return new Promise<User>((resolve, reject) => {
			this.http
				.get<User>(`users/${userGuid}`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error)
				);
		});
	}

	GetSelectableUsers(searchTerm: string = null): Observable<NgOption[]> {
		let params = new HttpParams();
		params = searchTerm != null ? params.append('searchTerm', searchTerm) : params;

		return this.http
			.get<SelectOption<string>[]>(`users/select-options`, { params })
			.pipe(map((data) => data.map((x) => ({ key: x.key, value: x.label }))));
	}

	Update<T>(userGuid: string, updateRequest: UpdateUser): Promise<T> {
		return new Promise<T>((resolve, reject) => {
			this.http
				.put<T>(`users/${userGuid}`, updateRequest)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error)
				);
		});
	}

	Create<T>(createRequest: CreateUser): Promise<T> {
		return new Promise<T>((resolve, reject) => {
			this.http
				.post<T>(`users/`, createRequest)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error)
				);
		});
	}

	Delete(userGuid: string): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			this.http
				.delete(`users/${userGuid}`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					() => resolve(),
					(error) => reject(error)
				);
		});
	}
}
