import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { InputTextModule } from './components/input-text/input-text.module';
import { InputTextareaModule } from './components/input-textarea/input-textarea.module';
import { ErrorMessagesModule } from './components/error-messages/error-messages.module';
import { InputPasswordModule } from './components/input-password/input-password.module';
import { LoadingScreenModule } from './components/loading-screen/loading-screen.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InputSelectModule } from './components/input-select/input-select.module';
import { ConfirmModalModule } from './components/confirm-modal/confirm-modal.module';
import { TokenExpirationModalModule } from './components/token-expiration-modal/token-expiration-modal.module';
import { InputMultiRangeModule } from '@shared/components/input-multi-range/input-multi-range.module';
import { InputSwitchModule } from '@shared/components/input-switch/input-switch.module';
import { PipeModule } from '@shared/pipes/pipe.module';
import { ImageUploadModalModule } from '@shared/components/image-upload-modal/image-upload-modal.module';
import { RouterModule } from '@angular/router';
import { SortableTableHeaderModule } from '@shared/components/sortable-table-header/sortable-table-header.module';
import { InputSelectAsyncModule } from '@shared/components/input-select-async/input-select-async.module';
import { InputDateModule } from '@shared/components/input-date/input-date.module';
import { InputNumberModule } from '@shared/components/input-number/input-number.module';
import { DateFnsModule } from 'ngx-date-fns';

@NgModule({
	imports: [CommonModule, PipeModule],
	exports: [
		CommonModule,
		FormsModule,
		RouterModule,
		ReactiveFormsModule,
		PipeModule,
		ConfirmModalModule,
		ImageUploadModalModule,
		InputDateModule,
		InputTextModule,
		InputTextareaModule,
		InputSwitchModule,
		InputMultiRangeModule,
		InputNumberModule,
		InputSelectModule,
		InputSelectAsyncModule,
		InputPasswordModule,
		ErrorMessagesModule,
		LoadingScreenModule,
		SortableTableHeaderModule,
		TokenExpirationModalModule,
		DateFnsModule,
		NgbModule,
	],
})
export class SharedModule {
}
