import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@guards/auth.guard';

const routes: Routes = [
	{
		path: '',
		children: [
			{ path: '', redirectTo: 'public', pathMatch: 'full' },
			{ path: 'public', loadChildren: () => import('./public/public.module').then((m) => m.PublicModule) },
			{ path: 'auth', loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule), canActivate: [AuthGuard] },
			{ path: 'error', loadChildren: () => import('./error/error.module').then((m) => m.ErrorModule) },
			{ path: '**', redirectTo: '/error/404', pathMatch: 'full' },
		],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
