import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputMultiRangeComponent } from './input-multi-range.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ErrorMessagesModule } from '../error-messages/error-messages.module';
import { NpnSliderModule } from 'npn-slider';

@NgModule({
	declarations: [InputMultiRangeComponent],
	exports: [InputMultiRangeComponent],
	imports: [CommonModule, FormsModule, NpnSliderModule, ReactiveFormsModule, ErrorMessagesModule],
})
export class InputMultiRangeModule {}
