import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextareaComponent } from './input-textarea.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ErrorMessagesModule } from '../error-messages/error-messages.module';

@NgModule({
	declarations: [InputTextareaComponent],
	exports: [InputTextareaComponent],
	imports: [CommonModule, FormsModule, ReactiveFormsModule, ErrorMessagesModule],
})

export class InputTextareaModule {}
