import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as rfdc from 'rfdc';

@Injectable()
export class CommonService {
	static ClearStorage(clearUnauth: boolean = true): void {
		if (clearUnauth) {
			localStorage.clear();
		} else {
			for (let i = 0; i < localStorage.length; i++) {
				let key = localStorage.key(i);
				if (!key.startsWith('unauth_')) {
					localStorage.removeItem(key);
				}
			}
		}
	}
	
	static GenerateGuid(): string {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
			const r = (Math.random() * 16) | 0,
				v = c === 'x' ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		});
	}

	static EmptyGuid(): string{
		return '00000000-0000-0000-0000-000000000000';
	}

	static ValidateForm(form: UntypedFormGroup) {
		Object.keys(form.controls).forEach((field) => {
			const control = form.get(field);
			control.markAsDirty({ onlySelf: true });
		});
	}

	static GenerateParams(obj: any): string {
		let params = new HttpParams();
		for (const key in obj) {
			if (obj.hasOwnProperty(key) && obj[key] != null) {
				if (Array.isArray(obj[key])) {
					for (const item of obj[key]) {
						params = params.append(key, item);
					}
				} else {
					params = params.append(key, obj[key]);
				}
			}
		}
		return params.toString();
	}

	static NullOrWhiteSpace(value: string): boolean {
		return value == null || value.toString().match(/^ *$/) !== null || value === '';
	}

	static DeepClone(object: any): any {
		if (object == null) {
			return null;
		}

		return rfdc()(object);
	}
}
