import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DestroyableComponent } from '@core/destroyable';
import { RelationTableFilter } from '@models/relation-table-filter.model';
import { takeUntil, map } from 'rxjs/operators';
import { CommonService } from './common.service';
import { Relation } from '@models/relation.model';
import { TablePageResult } from '@models/table-page-result.model';
import { RelationOverviewItem } from '@models/relation-overview-item.model';
import { Note } from '@models/note.model';
import { NoteOverviewItem } from '@models/note-overview-item.model';
import { Complaint } from '@models/complaint.model';
import { ComplaintOverviewItem } from '@models/complaint-overview-item.model';
import { Observable } from 'rxjs';
import { NgOption } from '@ng-select/ng-select';
import { SelectOption } from '@models/select-option.model';
import { RelationExportType } from '@models/relation-export-type.model';
import { saveAs } from 'file-saver';
import { RelationCompanyMatch } from '@models/relation-company-match.model';
import { HeatingNoteOverviewItem } from '@models/heating-overview-item.model';
import { HeatingNote } from '@models/heating-note.model';
import { VisitOverviewItem } from '@models/visit-overview-item.model';
import { RelationVisitOverviewItem } from '@models/relation-visit-overview-item.model';
import { RelationVisitTableFilter } from '@models/relation-visit-table-filter.model';

@Injectable()
export class RelationService extends DestroyableComponent {
	constructor(private http: HttpClient) {
		super();
	}

	GetAll(filters: RelationTableFilter): Promise<TablePageResult<RelationOverviewItem>> {
		return new Promise<TablePageResult<RelationOverviewItem>>((resolve, reject) => {
			const filterParams = CommonService.GenerateParams(filters);
			this.http
				.get<TablePageResult<RelationOverviewItem>>(`relations?${ filterParams }`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error),
				);
		});
	}

	Get(guid: string): Promise<Relation> {
		return new Promise<Relation>((resolve, reject) => {
			this.http
				.get<Relation>(`relations/${ guid }`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error),
				);
		});
	}
	
	GetMatches(guid: string): Promise<RelationCompanyMatch[]> {
		return new Promise<RelationCompanyMatch[]>((resolve, reject) => {
			this.http
				.get<RelationCompanyMatch[]>(`relations/matches/${ guid }`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error),
				);
		});
	}

	GetSelectableRelations(searchTerm: string = null, hideContractAlmostEnded: true): Observable<NgOption[]> {
		let params = new HttpParams();
		params = searchTerm != null ? params.append('searchTerm', searchTerm) : params;
		params = hideContractAlmostEnded != null ? params.append('hideContractAlmostEnded', hideContractAlmostEnded) : params;

		return this.http
			.get<SelectOption<string>[]>(`relations/select-options`, { params })
			.pipe(map((data) => data.map((x) => ({ key: x.key, value: x.label }))));
	}

	GetDefault(): Promise<Relation> {
		return new Promise<Relation>((resolve, reject) => {
			this.http
				.get<Relation>(`relations/default`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error),
				);
		});
	}

	Save(relation: Relation): Promise<string> {
		return new Promise<string>((resolve, reject) => {
			this.http
				.post<string>(`relations`, relation)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error),
				);
		});
	}

	Delete(guid: string): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			this.http
				.delete<void>(`relations/${guid}`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					() => resolve(),
					(error) => reject(error)
				);
		});
	}

	GetVacancyPercentage(relationGuid: string): Promise<number> {
		return new Promise<number>((resolve, reject) => {
			this.http
				.get<number>(`relations/${ relationGuid }/vacancy-percentage`)
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error),
				);
		});
	}
	
	GetNotes(relationGuid: string): Promise<NoteOverviewItem[]> {
		return new Promise<NoteOverviewItem[]>((resolve, reject) => {
			this.http
				.get<NoteOverviewItem[]>(`relations/${ relationGuid }/notes`)
				.pipe(takeUntil(this.ngUnsubscribe), map((items) => {
					for (const item of items) {
						item.created = new Date(item.created);
						item.lastModified = new Date(item.lastModified);
					}
					return items;
				}))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error),
				);
		});
	}
	
	GetByContractGuid(contractGuid: string): Promise<Relation> {
		return new Promise<Relation>((resolve, reject) => {
			this.http
				.get<Relation>(`contracts/${ contractGuid }/relation`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error),
				);
		});
	}

	UpdateAllStatuses(): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			this.http
				.get<void>(`relations/updatestatuses`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error),
				);
		});
	}

	GetTotalAmount(): Promise<number> {
		return new Promise<number>((resolve, reject) => {
			this.http
				.get<number>(`relations/total-amount`)
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error),
				);
		});
	}
	GetTotalAmountHubbs(): Promise<number> {
		return new Promise<number>((resolve, reject) => {
			this.http
				.get<number>(`relations/total-amount-hubbs`)
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error),
				);
		});
	}

	GenerateExcel(filters: RelationTableFilter, exportType: RelationExportType): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			const filterParams = CommonService.GenerateParams(filters);
			this.http.get(`relations/generate-excel?${ filterParams }&exportType=${ exportType }`, { observe: 'response', responseType: 'blob', headers: { 'X-No-Redirect': 'true' } })
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(response) => {
						const blob = new Blob([response.body],
							{type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
						const contentDisposition = response.headers.get('content-disposition');
						const fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim().replace(/"/g, '');
						saveAs(blob, fileName);
						resolve();
					},
					(error) => reject(error),
				);
		});
	}

	SaveNote(note: Note): Promise<Note> {
		return new Promise<Note>((resolve, reject) => {
			this.http
				.post<Note>(`relations/notes`, note)
				.pipe(takeUntil(this.ngUnsubscribe), map((note) => {
					note.created = new Date(note.created);
					note.lastModified = new Date(note.lastModified);

					return note;
				}))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error),
				);
		});
	}

	DeleteNote(noteGuid: string): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			this.http
				.delete<void>(`relations/notes/${ noteGuid }`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error),
				);
		});
	}

	GetComplaints(relationGuid: string): Promise<ComplaintOverviewItem[]> {
		return new Promise<ComplaintOverviewItem[]>((resolve, reject) => {
			this.http
				.get<ComplaintOverviewItem[]>(`relations/${ relationGuid }/complaints`)
				.pipe(takeUntil(this.ngUnsubscribe), map((items) => {
					for (const item of items) {
						item.created = new Date(item.created);
						item.lastModified = new Date(item.lastModified);
					}
					return items;
				}))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error),
				);
		});
	}

	SaveComplaint(complaint: Complaint): Promise<Complaint> {
		return new Promise<Note>((resolve, reject) => {
			this.http
				.post<Complaint>(`relations/complaints`, complaint)
				.pipe(takeUntil(this.ngUnsubscribe), map((complaint) => {
					complaint.created = new Date(complaint.created);
					complaint.lastModified = new Date(complaint.lastModified);
					return complaint;
				}))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error),
				);
		});
	}

	DeleteComplaint(complaintGuid: string): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			this.http
				.delete<void>(`relations/complaints/${ complaintGuid }`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error),
				);
		});
	}

	GetHeatingNotes(relationGuid: string): Promise<HeatingNoteOverviewItem[]> {
		return new Promise<HeatingNoteOverviewItem[]>((resolve, reject) => {
			this.http
				.get<HeatingNoteOverviewItem[]>(`relations/${ relationGuid }/heating`)
				.pipe(takeUntil(this.ngUnsubscribe), map((items) => {
					for (const item of items) {
						item.created = new Date(item.created);
						item.lastModified = new Date(item.lastModified);
					}
					return items;
				}))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error),
				);
		});
	}

	SaveHeatingNote(heatingNote: HeatingNote): Promise<HeatingNote> {
		return new Promise<Note>((resolve, reject) => {
			this.http
				.post<HeatingNote>(`relations/heating`, heatingNote)
				.pipe(takeUntil(this.ngUnsubscribe), map((heatingNote) => {
					heatingNote.created = new Date(heatingNote.created);
					heatingNote.lastModified = new Date(heatingNote.lastModified);
					return heatingNote;
				}))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error),
				);
		});
	}

	DeleteHeatingNote(heatingNoteGuid: string): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			this.http
				.delete<void>(`relations/heating/${ heatingNoteGuid }`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error),
				);
		});
	}
	
	GetAllVisits(filters: RelationTableFilter): Promise<TablePageResult<VisitOverviewItem>> {
		return new Promise<TablePageResult<VisitOverviewItem>>((resolve, reject) => {
			const filterParams = CommonService.GenerateParams(filters);
			this.http
				.get<TablePageResult<VisitOverviewItem>>(`relations/visits?${ filterParams }`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error),
				);
		});
	}
	
	GetRelationVisits(filters: RelationVisitTableFilter): Promise<TablePageResult<RelationVisitOverviewItem>> {
		return new Promise<TablePageResult<RelationVisitOverviewItem>>((resolve, reject) => {
			const filterParams = CommonService.GenerateParams(filters);
			this.http
				.get<TablePageResult<RelationVisitOverviewItem>>(`relations/visits/for-relation?${ filterParams }`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error),
				);
		});
	}
}
