import { PipeTransform, Pipe } from '@angular/core';
import { TranslationService } from '@services/translation.service';
@Pipe({
	name: 'translate',
	pure: true,
})
export class TranslatePipe implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	transform(key: string, activeLanguage: string, additionalParameters: string[] = []): string {
		return this.translationService.GetTranslation(key, activeLanguage, additionalParameters);
	}
}
