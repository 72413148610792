import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DestroyableComponent } from '@core/destroyable';
import { takeUntil } from 'rxjs/operators';
import { TargetManagementModel } from '@models/target-management.model';

@Injectable()
export class TargetManagementService extends DestroyableComponent {
	constructor(private http: HttpClient) {
		super();
	}

	Get(): Promise<TargetManagementModel[]> {
		return new Promise<TargetManagementModel[]>((resolve, reject) => {
			this.http
				.get<TargetManagementModel[]>(`target-management`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error)
				);
		});
	}

	Save(calculations: TargetManagementModel[]): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			this.http
				.post<boolean>(`target-management`, calculations)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error)
				);
		});
	}
}
