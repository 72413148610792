import { Component, OnInit, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { DestroyableComponent } from '@core/destroyable';
import { NgSelectConfig } from '@ng-select/ng-select';
import { LanguageService } from '@services/language.service';
import { TranslationService } from '@services/translation.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from '@services/common.service';

@Component({
	selector: 'hubbs-input-select-async',
	template: `
		<div class="form-floating" [class.mb-3]="control.enabled">
			<ng-select
				#select
				[id]="name"
				[items]="options | async"
				[bindLabel]="value"
				[multiple]="multiple"
				[placeholder]="labels.placeholder"
				[groupBy]="groupBy"
				[addTag]="addTag"
				[clearable]="clearable"
				[closeOnSelect]="!multiple"
				class="form-control"
				[class.p-0]="small"
				[class.pl-2]="small"
				[class.form-control-sm]="small"
				[disabled]="readonly || (control && !control.enabled)"
				[loading]="loading | async"
				[typeahead]="typeahead"
				[minTermLength]="minTermLength"
				[(ngModel)]="selectedValue"
				(ngModelChange)="modelChanged()"
				(open)="isOpen = true"
				(close)="isOpen = false"
				[ngClass]="{
					'is-invalid border-danger': control.invalid && (control.dirty || control.touched)
				}"
			>
				<ng-template ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="multiple">
					<div class="ng-value" *ngFor="let item of items | slice: 0:maxVisibleItems">
						<span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span><span class="ng-value-label">{{ value !== undefined ? item[value] : item }}</span>
					</div>
					<div class="ng-value" *ngIf="items.length - maxVisibleItems >= 1">
						<span class="ng-value-label">{{ (labels?.maxVisibleItemText ? labels?.maxVisibleItemText : '')| replace:'{0}':(items.length - maxVisibleItems | string)  }}</span>
					</div>
				</ng-template>
				<ng-template ng-loadingspinner-tmp>
					<div class="lds-ellipsis">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</ng-template>
			</ng-select>
			<label [for]="name + randomId">
				{{ labels.label }}
				<span *ngIf="validator && !control.disabled"> *</span>
			</label>
			<hubbs-error-messages [control]="control" [label]="labels.label"></hubbs-error-messages>
		</div>
	`,
	styleUrls: ['./input-select-async.component.scss'],
})
export class InputSelectAsyncComponent extends DestroyableComponent implements OnInit {
	@ViewChild('select', { static: true }) inputField: ElementRef;

	@Input() label: string;
	@Input() name: string;
	@Input() typeToSearchText: string;
	@Input() small: boolean;
	@Input() focus: boolean;
	@Input() control: AbstractControl;
	@Input() multiple: boolean = false;
	@Input() placeholder: string = '';
	@Input() options: Observable<any[]>;
	@Input() key: string = 'key';
	@Input() value: string = 'value';
	@Input() groupBy: string;
	@Input() maxVisibleItems: number = 2;
	@Input() clearable: boolean = true;
	@Input() readonly: boolean = false;
	@Input() inputOnly: boolean = false;
	@Input() addTag: boolean | ((value: string) => void) = false;
	@Input() loading: Subject<boolean>;
	@Input() typeahead: Subject<string>;
	@Input() minTermLength: number = 0;
	@Input() selectedValue: any;
	@Output() selectedValueChange: EventEmitter<any> = new EventEmitter();

	@Input() translate: boolean = true;

	labels: { [key: string]: string } = {};
	randomId: string;
	
	isOpen: boolean;

	constructor(private config: NgSelectConfig, private translationService: TranslationService) {
		super();

		LanguageService.LanguageChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.setLabels();
		});

		this.randomId = CommonService.GenerateGuid();
	}

	ngOnInit() {
		this.setLabels();

		if (this.focus) {
			if (!this.inputField?.nativeElement) {
				console.warn(`Couldn't focus field ${this.label}!`);
				return;
			}

			this.inputField.nativeElement.focus();
		}
	}

	trackByFn(item: any) {
		return item;
	}

	modelChanged() {
		this.selectedValueChange.emit(this.selectedValue);

		if (this.control) {
			this.control.setValue(this.selectedValue ? this.selectedValue[this.key] : null);
		}
	}

	private setLabels(): void {
		this.config.loadingText = this.translationService.GetTranslation('select_input_loading');
		this.config.clearAllText = this.translationService.GetTranslation('select_input_clearAll');
		this.config.notFoundText = this.translationService.GetTranslation('select_input_notFound');
		this.config.addTagText = this.translationService.GetTranslation('select_input_addTag');

		this.labels.selectAllText = this.translationService.GetTranslation('select_input_selectAll');
		this.labels.deselectAllText = this.translationService.GetTranslation('select_input_deselectAll');
		this.labels.maxVisibleItemText = this.translationService.GetTranslation('select_input_maxVisibleItemText');

		if (this.translate) {
			this.labels.label = this.translationService.GetTranslation(this.label);
			this.labels.placeholder = this.translationService.GetTranslation(this.placeholder);
			this.config.typeToSearchText = this.translationService.GetTranslation(this.typeToSearchText ?? 'select_input_typeToSearch');
		} else {
			this.labels.label = this.label;
			this.labels.placeholder = this.placeholder;
			this.config.typeToSearchText = this.typeToSearchText ?? this.translationService.GetTranslation('select_input_typeToSearch');
		}
	}
	
	get validator() {
		if (!this.control.validator) {
			return false;
		}
		const validator = this.control.validator({} as AbstractControl);
		if (validator && validator.required) {
			return true;
		}

		return false;
	}
}
