import { Injectable } from '@angular/core';
import { DestroyableComponent } from '@app/core/destroyable';
import { DefaultLanguage, Translations } from '@translations/translations';
import { LanguageService } from './language.service';
import { CommonService } from '@services/common.service';

@Injectable()
export class TranslationService extends DestroyableComponent {
	constructor(private languageService: LanguageService) {
		super();
	}

	public GetTranslation(key: string, cultureCode: string = 'nl-NL', additionalParameters: string[] = []): string {
		if (CommonService.NullOrWhiteSpace(key)) {
			return '';
		}

		key = key.toLowerCase();

		if (cultureCode == null) {
			cultureCode = this.languageService.GetActiveLanguage();
		}

		const translations = Translations[cultureCode];
		if (!translations) {
			return Translations[DefaultLanguage][key] ?? `[${key}-${cultureCode}]`;
		}

		let translation = translations[key] ?? Translations[DefaultLanguage][key] ?? `[${key}-${cultureCode}]`;

		for (let i = 0; i < additionalParameters.length; i++) {
			translation = translation.replace(`{${i}}`, additionalParameters[i]);
		}

		return translation;
	}
}
