import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DestroyableComponent } from '@core/destroyable';
import { ImageLinkRequest } from '@models/image-link-request.model';
import { ImageType } from '@models/image-type.model';
import { ImageUploadRequest } from '@models/image-upload-request.model';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from '@services/common.service';

@Injectable()
export class ImageService extends DestroyableComponent {
	constructor(private http: HttpClient) {
		super();
	}

	Upload<T>(imageUpload: ImageUploadRequest): Promise<T> {
		return new Promise<T>((resolve, reject) => {
			this.http
				.post<T>(`images`, imageUpload)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error),
				);
		});
	}

	Get<T>(imageGuid: string): Promise<T> {
		return new Promise<T>((resolve, reject) => {
			this.http
				.get<T>(`images/${ imageGuid }`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error),
				);
		});
	}

	GetByType<T>(identifier: string, type: ImageType): Promise<T> {
		identifier ??= CommonService.EmptyGuid();
		
		return new Promise<T>((resolve, reject) => {
			this.http
				.get<T>(`images/type?identifier=${ identifier }&type=${ type }`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error),
				);
		});
	}

	LinkByType<T>(imageLinkRequest: ImageLinkRequest): Promise<T> {
		return new Promise<T>((resolve, reject) => {
			this.http
				.post<T>(`images/type`, imageLinkRequest)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error),
				);
		});
	}
}
