import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputSwitchComponent } from './input-switch.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ErrorMessagesModule } from '../error-messages/error-messages.module';
import { PipeModule } from '@shared/pipes/pipe.module';

@NgModule({
	declarations: [InputSwitchComponent],
	exports: [InputSwitchComponent],
	imports: [CommonModule, FormsModule, ReactiveFormsModule, ErrorMessagesModule, PipeModule],
})
export class InputSwitchModule {}
