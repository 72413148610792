import { NgModule, InjectionToken } from '@angular/core';

export const APP_CONFIG = new InjectionToken<AppConfig>('app.config');

export class AppConfig {
	public environmentName!: string;
	public apiEndpoint!: string;
	public target!: number;
}

@NgModule({})
export class AppConfigModule {}
