import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { registerLocaleData } from '@angular/common';
import localeNL from '@angular/common/locales/nl';
import { SharedModule } from '@shared/shared.module';
import { BreakpointService } from '@services/breakpoint.service';
import { LanguageService } from '@services/language.service';
import { TranslationService } from '@services/translation.service';
import { CommonService } from '@services/common.service';
import { ToastrModule } from 'ngx-toastr';
import { LoadingService } from '@services/loading.service';
import { AuthenticationService } from '@services/authentication.service';
import { InterceptorService } from '@services/interceptor.service';
import { AuthGuard } from '@guards/auth.guard';
import { UnAuthGuard } from '@guards/un-auth.guard';
import { ValidationService } from '@services/validation.service';
import { UserService } from '@services/user.service';
import { ConfirmationService } from '@services/confirmation.service';
import { ImageService } from '@services/image.service';
import { TokenService } from '@services/token.service';
import { DashboardService } from '@services/dashboard.service';
import { ContractService } from '@services/contract.service';
import { CompanyService } from '@services/company.service';
import { RelationService } from '@services/relation.service';
import { EditableService } from '@services/editable.service';
import { DateFnsConfigurationService, DateFnsModule } from 'ngx-date-fns';
import { AlertService } from '@services/alert.service';
import { nl } from 'date-fns/locale';
import { OutSmartService } from '@services/out-smart.service';
import {ReportService} from "@services/report.service";
import { MailHistoryService } from '@services/mail-history.service';
import { RentCalculationService } from '@services/rent-calculation.service';

const dateFnsConfig = new DateFnsConfigurationService();
dateFnsConfig.setLocale(nl);

registerLocaleData(localeNL, 'nl-NL');

export const getUTCOffsetDate = (date = new Date()): Date => {
	return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()))
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule, BrowserAnimationsModule, DateFnsModule.forRoot(), HttpClientModule, AppRoutingModule, SharedModule, ToastrModule.forRoot({
			maxOpened: 1,
			preventDuplicates: true,
			positionClass: 'toast-bottom-full-width',
			progressBar: true,
		}),
	],
	providers: [
		AlertService,
		AuthenticationService,
		BreakpointService,
		CommonService,
		ConfirmationService,
		ImageService,
		DashboardService,
		ContractService,
		CompanyService,
		RelationService,
		LanguageService,
		LoadingService,
		TranslationService,
		TokenService,
		EditableService,
		UserService,
		ValidationService,
		ReportService,
		OutSmartService,
		MailHistoryService,
		RentCalculationService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: InterceptorService,
			multi: true,
		},

		AuthGuard,
		UnAuthGuard,

		{ provide: DateFnsConfigurationService, useValue: dateFnsConfig } ,
		{ provide: LOCALE_ID, useValue: 'nl-NL' },
	],
	bootstrap: [AppComponent],
})
export class AppModule {
}
