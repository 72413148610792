import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
	name: 'objectKeys',
	pure: true,
})
export class ObjectKeysPipe implements PipeTransform {
	transform(value: object): any[] {
		if (value == null) {
			return null;
		}
		
		return Object.keys(value);
	}
}
