import { Component, OnInit, ViewChild } from '@angular/core';
import { DestroyableComponent } from '@core/destroyable';
import { DialogContent } from '@models/dialog-content.model';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'hubbs-confirm-modal',
	template: `
		<ng-template #content let-modal>
			<div class="modal-header d-flex align-items-center">
				<h5 class="title m-0">{{ dialogContent.title }}</h5>
				<button type="button" class="btn" (click)="close()">
					<i class="fas fa-times align-middle"></i>
				</button>
			</div>
			<div class="modal-body px-3">
				<p class="mb-3" [innerHTML]="dialogContent.text"></p>
			</div>
			<div class="modal-footer p-3">
				<div class="w-100 m-0 d-flex justify-content-between">
					<ng-container *ngIf="dialogContent.confirmation">
						<button type="button" (click)="decline()" class="btn btn-hubbs-cancel float-start  ml-0">
							<span>
								{{ dialogContent.declineButtonText }}
							</span>
						</button>
						<button type="button" (click)="accept()" class="btn btn-hubbs-red float-end">
							<span>
								{{ dialogContent.acceptButtonText }}
							</span>
						</button>
					</ng-container>
					<ng-container *ngIf="dialogContent.information">
						<div class="text-center">
							<button type="button" (click)="ok()" class="btn btn-hubbs-red float-end">
								<span>
									{{ dialogContent.okButtonText }}
								</span>
							</button>
						</div>
					</ng-container>
				</div>
			</div>
		</ng-template>
	`,
	styles: [],
})
export class ConfirmModalComponent extends DestroyableComponent {
	@ViewChild('content', { static: false }) protected content: any;

	protected modalOptions: NgbModalOptions = {};
	protected modalReference: NgbModalRef;

	dialogContent: DialogContent;

	constructor(private modalService: NgbModal) {
		super();
	}

	showConfirmation(dialogContent: DialogContent): Promise<boolean> {
		this.dialogContent = dialogContent;

		this.modalOptions.ariaLabelledBy = 'title';
		this.modalOptions.keyboard = false;
		this.modalOptions.backdrop = 'static';
		this.modalOptions.size = dialogContent.size;
		this.modalOptions.windowClass = 'modal-confirm';
		this.modalOptions.centered = dialogContent.centered;

		this.modalReference = this.modalService.open(this.content, this.modalOptions);
		return this.modalReference.result;
	}

	public close(): void {
		this.modalReference.close(null);
	}

	public decline(): void {
		this.modalReference.close(false);
	}

	public accept(): void {
		this.modalReference.close(true);
	}

	public ok(): void {
		this.modalReference.close(true);
	}
}
