import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DestroyableComponent } from '@core/destroyable';
import { CompanyTableFilter } from '@models/company-table-filter.model';
import { map, takeUntil } from 'rxjs/operators';
import { CommonService } from './common.service';
import { Company } from '@models/company.model';
import { CompanyOverviewItem } from '@models/company-overview-item.model';
import { TablePageResult } from '@models/table-page-result.model';
import { Relation } from '@models/relation.model';
import { Observable } from 'rxjs';
import { NgOption } from '@ng-select/ng-select';
import { SelectOption } from '@models/select-option.model';
import { RelationTableFilter } from '@models/relation-table-filter.model';
import { saveAs } from 'file-saver';
import { CompanyDemand } from '@models/company-demand.model';
import { Note } from '@models/note.model';
import { companyNote } from '@models/company-note.model';
import { NoteOverviewItem } from '@models/note-overview-item.model';
import { CompanyNoteOverviewItem } from '@models/company-note-overview-item.model';

@Injectable()
export class CompanyService extends DestroyableComponent {
	constructor(private http: HttpClient) {
		super();
	}
	
	GetAll(filters: CompanyTableFilter): Promise<TablePageResult<CompanyOverviewItem>> {
		return new Promise<TablePageResult<CompanyOverviewItem>>((resolve, reject) => {
			const filterParams = CommonService.GenerateParams(filters);
			this.http
				.get<TablePageResult<CompanyOverviewItem>>(`companies?${filterParams}`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error)
				);
		});
	}

	Get(guid: string): Promise<Company> {
		return new Promise<Company>((resolve, reject) => {
			this.http
				.get<Company>(`companies/${guid}`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error)
				);
		});
	}

	GetDemands(guid: string, active: boolean): Promise<CompanyDemand[]> {
		return new Promise<CompanyDemand[]>((resolve, reject) => {
			this.http
				.get<CompanyDemand[]>(`companies/company-demands/${guid}/${active}`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error)
				);
		});
	}
	AddDemand(companyDemand: CompanyDemand): Promise<CompanyDemand> {
		return new Promise<CompanyDemand>((resolve, reject) => {
			this.http
				.post<CompanyDemand>(`companies/company-demands/add`, companyDemand)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error)
				);
		});
	}
	DeleteDemand(demandGuid: string): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			this.http
				.delete<void>(`companies/company-demands/delete/${demandGuid}`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error)
				);
		});
	}

	GetSelectableCompanies(searchTerm: string = null): Observable<NgOption[]> {
		let params = new HttpParams();
		params = searchTerm != null ? params.append('searchTerm', searchTerm) : params;

		return this.http
			.get<SelectOption<string>[]>(`companies/select-options`, { params })
			.pipe(map((data) => data.map((x) => ({ key: x.key, value: x.label }))));
	}
	
	Save(company: Company): Promise<string> {
		return new Promise<string>((resolve, reject) => {
			this.http
				.post<string>(`companies`, company)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error)
				);
		});
	}

	GetByContractGuid(contractGuid: string): Promise<Company> {
		return new Promise<Company>((resolve, reject) => {
			this.http
				.get<Company>(`contracts/${contractGuid}/company`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error)
				);
		});
	}

	GenerateExcel(filters: CompanyTableFilter): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			const filterParams = CommonService.GenerateParams(filters);
			this.http.get(`companies/generate-excel?${ filterParams }`, { observe: 'response', responseType: 'blob', headers: { 'X-No-Redirect': 'true' } })
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(response) => {
						const blob = new Blob([response.body],
							{type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
						const contentDisposition = response.headers.get('content-disposition');
						const fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim().replace(/"/g, '');
						saveAs(blob, fileName);
						resolve();
					},
					(error) => reject(error),
				);
		});
	}
	SaveNote(note: companyNote): Promise<companyNote> {
		return new Promise<companyNote>((resolve, reject) => {
			this.http
				.post<companyNote>(`companies/notes`, note)
				.pipe(takeUntil(this.ngUnsubscribe), map((note) => {
					note.created = new Date(note.created);
					note.lastModified = new Date(note.lastModified);

					return note;
				}))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error),
				);
		});
	}
	DeleteNote(noteGuid: string): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			this.http
				.delete<void>(`companies/notes/${ noteGuid }`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error),
				);
		});
	}
	GetNotes(companyGuid: string): Promise<CompanyNoteOverviewItem[]> {
		return new Promise<CompanyNoteOverviewItem[]>((resolve, reject) => {
			this.http
				.get<CompanyNoteOverviewItem[]>(`companies/${ companyGuid }/notes`)
				.pipe(takeUntil(this.ngUnsubscribe), map((items) => {
					for (const item of items) {
						item.created = new Date(item.created);
						item.lastModified = new Date(item.lastModified);
					}
					return items;
				}))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error),
				);
		});
	}
}
