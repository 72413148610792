import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { DestroyableComponent } from '@core/destroyable';
import { takeUntil, map } from 'rxjs/operators';
import { AlertDto } from '@models/alert-dto.model';
import { AlertCount } from '@models/alert-count.model';

@Injectable()
export class AlertService extends DestroyableComponent {
	static ReadStateUpdated: EventEmitter<void> = new EventEmitter();
	
	constructor(private http: HttpClient) {
		super();
	}

	GetAll(excludeRead: boolean): Promise<AlertDto[]> {
		return new Promise<AlertDto[]>((resolve, reject) => {
			this.http
				.get<AlertDto[]>(`alerts?excludeRead=${excludeRead}`)
				.pipe(
					takeUntil(this.ngUnsubscribe),
					map((result: AlertDto[]) => {
						result = result.map(x => {
							x.alert.created = new Date(x.alert.created);
							return x;
						});
						
						return result;
					})
				)
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error)
				);
		});
	}

	GetCount(): Promise<AlertCount> {
		return new Promise<AlertCount>((resolve, reject) => {
			this.http
				.get<AlertCount>(`alerts/count`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error)
				);
		});
	}
 
	MarkAsRead(guid: string): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			this.http
				.put<void>(`alerts/${guid}/read`, null)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					() => {
						AlertService.ReadStateUpdated.emit();
						resolve();
					},
					(error) => reject(error)
				);
		});
	}

	Delete(guid: string): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			this.http
				.delete<void>(`alerts/${guid}`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					() => resolve(),
					(error) => reject(error)
				);
		});
	}
}
