import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputNumberComponent } from './input-number.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ErrorMessagesModule } from '../error-messages/error-messages.module';

@NgModule({
	declarations: [InputNumberComponent],
	exports: [InputNumberComponent],
	imports: [CommonModule, FormsModule, ReactiveFormsModule, ErrorMessagesModule],
})
export class InputNumberModule {}
