import { EventEmitter, Injectable } from '@angular/core';
import { DestroyableComponent } from '@core/destroyable';
import { DefaultLanguage } from '@translations/translations';

@Injectable()
export class LanguageService extends DestroyableComponent {
	static LanguageChanged: EventEmitter<string> = new EventEmitter();

	public SetActiveLanguage(cultureCode: string): void {
		localStorage.setItem('language', cultureCode);
		LanguageService.LanguageChanged.emit(cultureCode);
	}

	public GetActiveLanguage(): string {
		return localStorage.getItem('language') ?? DefaultLanguage;
	}
}
