import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { DestroyableComponent } from '@core/destroyable';
import { ValidationRequest } from '@models/validation-request.model';
import { ValidationResponse } from '@models/validation-response.model';
import { ValidationType } from '@models/validation-type.model';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { CommonService } from './common.service';
import { isAfter, isBefore, isEqual, isValid, parse } from 'date-fns';

@Injectable()
export class ValidationService extends DestroyableComponent {
	constructor(private http: HttpClient) {
		super();
	}
	
	static unknownValidator(control){
		if (control.value === '' || control.value == null || control.value === 'unknown') {
			return { required: true };
		}

		return null;
	}

	static passwordValidator(control) {
		if (control.value === '' || control.value == null) {
			return null;
		}

		return control.value.match(/^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/) ? null : { invalidPassword: true };
	}

	static compareValidator(value1: string, value2: string) {
		return (group: UntypedFormGroup) => {
			if (!group?.controls) {
				return null;
			}

			const f = group.controls[value1];
			const t = group.controls[value2];
			if (f.value !== t.value) {
				group.controls[value2].setErrors({ invalidCompare: true });
				return { invalidCompare: true };
			}
			return null;
		};
	}

	static serverValidation(control: string, currentValue: string, type: ValidationType, validationService: ValidationService) {
		return (group: UntypedFormGroup) => {
			if (!group?.controls) {
				return null;
			}

			const c = group.controls[control];
			if (!c.valid || currentValue.toLowerCase() === c.value.toLowerCase()) {
				return null;
			}

			const validationRequest: ValidationRequest = {
				value: c.value.toLowerCase(),
				type,
			};

			validationService.IsValid<ValidationResponse>(validationRequest).then(
				(result) => {
					if (!result.isValid) {
						let error = null;
						switch (type) {
							case ValidationType.uniqueEmail:
								error = { uniqueEmail: true };
								break;
						}

						c.setErrors(error);
						return error;
					} else {
						return null;
					}
				},
				() => {
					return null;
				}
			);
		};
	}

	static dateValidator(control) {
		if (control.value === '' || control.value == null) {
			return null;
		}

		if (control.value instanceof String && !control.value.match(/^([0][0-9]|[1][0-9]|[2][0-9]|[3][0-1])-([0][0-9]|[1][0-2])-[0-9]{4}$/)) {
			return { invalidDate: true };
		}

		const controlDate = typeof control.value === 'string' ? parse(control.value, 'dd-MM-yyyy', new Date()) : control.value;
		return isValid(controlDate) ? null : { invalidDate: true };
	}

	static isFormValid(form: UntypedFormGroup, emitStatus: boolean = true): boolean {
		for (const i in form.controls) {
			if (form.controls.hasOwnProperty(i)) {
				form.controls[i].markAsTouched();
				if (emitStatus) {
					(form.controls[i].statusChanges as EventEmitter<any>).emit(form.controls[i].status);
				}
			}
		}
		return form.valid;
	}
	
	IsValid<T>(validationRequest: ValidationRequest): Promise<T> {
		return new Promise<T>((resolve, reject) => {
			const filterParams = CommonService.GenerateParams(validationRequest);
			this.http
				.get<T>(`validation?${filterParams}`)
				.pipe(debounceTime(500), takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error)
				);
		});
	}
}
