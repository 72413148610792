import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TokenExpirationModalComponent } from './token-expiration-modal.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
	declarations: [TokenExpirationModalComponent],
	exports: [TokenExpirationModalComponent],
	imports: [CommonModule, NgbModalModule],
})
export class TokenExpirationModalModule {}
