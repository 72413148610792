import { Component, OnDestroy, Directive } from '@angular/core';
import { Subject } from 'rxjs';

@Directive()
export abstract class DestroyableDirective implements OnDestroy {
	protected ngUnsubscribe: Subject<void> = new Subject();

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);
		this.ngUnsubscribe.complete();
		this.ngUnsubscribe = new Subject();
	}
}

@Component({ selector: 'hubbs-destroyable', template: `` })
export abstract class DestroyableComponent implements OnDestroy {
	protected ngUnsubscribe: Subject<void> = new Subject();

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);
		this.ngUnsubscribe.complete();
		this.ngUnsubscribe = new Subject();
	}
}
