import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputSelectComponent } from './input-select.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ErrorMessagesModule } from '../error-messages/error-messages.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { PipeModule } from '@shared/pipes/pipe.module';

@NgModule({
	declarations: [InputSelectComponent],
	exports: [InputSelectComponent],
	imports: [CommonModule, NgSelectModule, FormsModule, ReactiveFormsModule, ErrorMessagesModule, PipeModule],
})
export class InputSelectModule {}
