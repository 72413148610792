import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DestroyableComponent } from '@core/destroyable';
import { takeUntil, map } from 'rxjs/operators';
import { CommonService } from './common.service';
import { TablePageResult } from '@models/table-page-result.model';
import { MailHistoryOverviewItem } from '@models/mail-history-overview-item.model';
import { TableFilter } from '@models/table-filter.model';

@Injectable()
export class MailHistoryService extends DestroyableComponent {
	constructor(private http: HttpClient) {
		super();
	}

	GetAll(filters: TableFilter): Promise<TablePageResult<MailHistoryOverviewItem>> {
		return new Promise<TablePageResult<MailHistoryOverviewItem>>((resolve, reject) => {
			const filterParams = CommonService.GenerateParams(filters);
			this.http
				.get<TablePageResult<MailHistoryOverviewItem>>(`mail-history?${ filterParams }`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error),
				);
		});
	}
}
