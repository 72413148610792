import { Component, Input } from '@angular/core';
import { BaseComponent } from '@core/base.component';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'hubbs-loading-screen',
	template: `<section *ngIf="isLoading$ | async">
		<div class="loading-box d-flex justify-content-center align-items-center flex-column">
			<div class="loader"></div>
			<strong class="mt-3 font-weight-normal text-center w-100 d-block text-white" [innerHTML]="title"></strong>
		</div>
	</section> `,
})
export class LoadingScreenComponent extends BaseComponent {
	@Input() title: string;
	@Input() isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
}
