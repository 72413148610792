import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DestroyableComponent } from '@core/destroyable';
import { ChangePassword } from '@models/change-password.model';
import { ForgotPassword } from '@models/forgot-password.model';
import { JwtTokenData } from '@models/jwt-token-data.model';
import { Login } from '@models/login.model';
import { UserStatus } from '@models/user-status.model';
import { takeUntil } from 'rxjs/operators';
import { Observable, Observer } from 'rxjs';
import { VerifyNewTFASecret } from '@models/verify-new-tfa-secret.model';
import { Token } from '@models/token.model';
import { VerifyTFACode } from '@models/verify-tfa-code.model';
import { WhitelistStatus } from '@models/whitelist-status.model';
import { User } from '@models/user.model';
import { UserTableFilter } from '@models/user-table-filter.model';
import { TablePageResult } from '@models/table-page-result.model';
import { CommonService } from '@services/common.service';
import { Whitelist } from '@models/whitelist.model';
import { WhitelistTableFilter } from '@models/whitelist-table-filter.model';

@Injectable()
export class AuthenticationService extends DestroyableComponent {
	constructor(private http: HttpClient, private router: Router) {
		super();
	}

	public static LogOutRequested: EventEmitter<void> = new EventEmitter();
	public static TokenCleared: EventEmitter<void> = new EventEmitter();
	public static ClaimsUpdated: EventEmitter<void> = new EventEmitter();

	private static JwtTokenData: JwtTokenData;

	public static get TokenData(): JwtTokenData {
		if (this.JwtTokenData != null) {
			return this.JwtTokenData;
		} else {
			return this.SetTokenData();
		}
	}

	public static SetTokenData(): JwtTokenData {
		const claims = this.getClaims(false);
		let tokenData: JwtTokenData = null;
		if (claims != null) {
			tokenData = {
				avatar: claims.user_avatar,
				name: claims.user_name,
				email: claims.user_email,
				status: Number(claims.user_status) as UserStatus,
				exp: Number(claims.exp) * 1000,
				guid: claims.sub,
			};
		}

		this.JwtTokenData = tokenData;
		this.ClaimsUpdated.emit();

		return tokenData;
	}

	public static ClearToken(): void {
		localStorage.removeItem('auth_token');
		this.TokenCleared.emit();
	}

	public static HasValidToken(): Promise<boolean> {
		return new Promise<boolean>((resolve) => {
			const claims = this.getClaims(false);

			if (claims == null) {
				resolve(false);
			} else {
				resolve(new Date(claims.exp * 1000) > new Date());
			}
		});
	}

	private static getToken(): string {
		return localStorage.getItem('auth_token');
	}

	private static getClaims(tfa: boolean) {
		let token;

		if (tfa) {
			token = this.GetTFAToken();
		} else {
			token = this.getToken();
		}

		if (token == null) {
			return null;
		}

		const parts = token.split('.');
		if (parts.length !== 3) {
			return null;
		}

		const decoded = this.urlBase64Decode(parts[1]);
		if (!decoded) {
			return null;
		}
		return JSON.parse(decoded);
	}

	private static urlBase64Decode(str: string): string {
		let output = str.replace(/-/g, '+').replace(/_/g, '/');
		switch (output.length % 4) {
			case 0: {
				break;
			}
			case 2: {
				output += '==';
				break;
			}
			case 3: {
				output += '=';
				break;
			}
			default: {
				throw new Error('Illegal base64url string!');
			}
		}
		return decodeURIComponent(
			atob(output)
				.split('')
				.map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
				.join(''),
		);
	}

	public static GetTFAToken(): string {
		return localStorage.getItem('TFAToken');
	}

	private getToken(): string {
		return localStorage.getItem('auth_token');
	}


	private getClaims(tfa: boolean) {
		let token;

		if (tfa) {
			token = this.GetTFAToken();
		} else {
			token = this.getToken();
		}

		if (token == null) {
			return null;
		}

		const parts = token.split('.');
		if (parts.length !== 3) {
			return null;
		}

		const decoded = this.urlBase64Decode(parts[1]);
		if (!decoded) {
			return null;
		}

		return JSON.parse(decoded);
	}


	private urlBase64Decode(str: string): string {
		let output = str.replace(/-/g, '+').replace(/_/g, '/');
		switch (output.length % 4) {
			case 0: {
				break;
			}
			case 2: {
				output += '==';
				break;
			}
			case 3: {
				output += '=';
				break;
			}
			default: {
				throw new Error('Illegal base64url string!');
			}
		}
		return decodeURIComponent(
			atob(output)
				.split('')
				.map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
				.join(''),
		);
	}

	public Authenticate(loginModel: Login): Observable<Token> {
		let authenticationService = this;
		return Observable.create((observer: Observer<Token>) => {
			this.http.post<Token>('authenticate', loginModel).subscribe(data => {
				CommonService.ClearStorage(false);
				if (data.isValid) {
					if (data.requiresTFA) {
						authenticationService.SetTFAToken(data.tokenString);
					} else {
						authenticationService.SetToken(data.tokenString);
					}
				}
				observer.next(data);
				observer.complete();
			});
		});
	}

	GetAllWhitelist(filters: WhitelistTableFilter): Promise<TablePageResult<Whitelist>> {
		return new Promise<TablePageResult<Whitelist>>((resolve, reject) => {
			const filterParams = CommonService.GenerateParams(filters);
			this.http
				.get<TablePageResult<Whitelist>>(`whitelist/getall?${ filterParams }`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error),
				);
		});
	}

	public UpdateWhitelist(guid: string, status: WhitelistStatus): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			this.http
				.put<void>(`whitelist/update/${ guid }/${ status }`, {})
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error),
				);
		});
	}

	public IsAwaitingTFA(): Observable<boolean> {
		return Observable.create((observer: Observer<boolean>) => {
			observer.next(this.GetTFAToken() != null);
			observer.complete();
		});
	}

	public GetTFAUser() {
		let claims = this.getClaims(true);
		if (claims != null) {
			let user = claims['user_guid'];
			return user;
		} else {
			return null;
		}
	}

	public GetUser() {
		let claims = this.getClaims(false);
		if (claims != null) {
			let user = claims['user_guid'];
			return user;
		} else {
			return null;
		}
	}

	public VerifyTFACode(code: string): Observable<Token> {
		let user = this.GetTFAUser();
		let verify: VerifyTFACode = new VerifyTFACode();
		verify.userGuid = user;
		verify.code = code;

		let authenticationService = this;
		return Observable.create((observer: Observer<Token>) => {
			this.http.post<Token>('tfa/verifycode', verify).subscribe(data => {
				if (data.isValid) {
					CommonService.ClearStorage(false);
					authenticationService.SetToken(data.tokenString);
				}
				observer.next(data);
				observer.complete();
			});
		});
	}

	public SetToken(token: string): void {
		localStorage.removeItem('TFAToken');
		localStorage.setItem('auth_token', token);
	}

	public SetTFAToken(token: string): void {
		localStorage.setItem('TFAToken', token);
	}

	public GetTFAToken(): string {
		return localStorage.getItem('TFAToken');
	}

	// public RenewToken(): Promise<void> {
	// 	return new Promise<void>((resolve, reject) => {
	// 		this.http
	// 			.get<void>('renew-token')
	// 			.pipe(takeUntil(this.ngUnsubscribe))
	// 			.subscribe(
	// 				() => resolve(),
	// 				(error) => reject(error),
	// 			);
	// 	});
	// }

	public SignOut(): Promise<void> {
		return new Promise<void>((resolve) => {
			AuthenticationService.TokenData;

			localStorage.removeItem('language');
			localStorage.removeItem('auth_token');

			sessionStorage.clear();

			this.router.navigate(['/public']);

			resolve();
		});
	}

	public ForgotPassword<T>(forgotPasswordModel: ForgotPassword): Promise<T> {
		return new Promise<T>((resolve, reject) => {
			this.http
				.post<T>('forgot-password', forgotPasswordModel)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error),
				);
		});
	}

	public ChangePassword(changePasswordModel: ChangePassword): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			AuthenticationService.ClearToken();
			this.http
				.post<void>('change-password', changePasswordModel)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					() => resolve(),
					(error) => reject(error),
				);
		});
	}

	public CreateNewTFASecret(username: string): Observable<string[]> {
		return this.http.get<string[]>('tfa/new/' + username);
	}

	public DisableTFA(userGuid: string): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			this.http
				.put<void>(`tfa/disable/${ userGuid }`, {})
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error),
				);
		});
	}

	public VerifyNewTFASecret(secret: string, code: string, userGuid: string): Observable<boolean> {
		let verify: VerifyNewTFASecret = new VerifyNewTFASecret();
		verify.secret = secret;
		verify.code = code;
		verify.userGuid = userGuid;

		return this.http.post<boolean>('tfa/verifynewsecret', verify);
	}
}
