import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {DestroyableComponent} from '@core/destroyable';
import {CommonService} from '@services/common.service';
import {LanguageService} from '@services/language.service';
import {TranslationService} from '@services/translation.service';
import {takeUntil} from 'rxjs/operators';
import { BaseComponent } from '@core/base.component';

@Component({
	selector: 'hubbs-input-switch',
	template: `
		<div class="form-floating switch" [class.mb-3]="control.enabled" [class.border]="control.enabled" *ngIf="!standalone">
			<input
				#input
				*ngIf="!control.enabled"
				[disabled]="!control.enabled"
				[placeholder]="labels.label"
				class="form-control form-control-lg"
				[value]="'enum_' + control.value | translate: activeLanguage"
				[ngClass]="{
					'is-invalid border-danger': control.invalid && (control.dirty || control.touched)
				}"
			/>
			<label [for]="name + randomId">
				{{ labels.label }}
				<span *ngIf="validator && !control.disabled"> *</span>
			</label>
			<div class="form-check form-switch" *ngIf="control.enabled">
				<input class="form-check-input"
					   [name]="name + randomId"
					   [id]="name + randomId" type="checkbox"
					   [formControl]="control" />
			</div>
			<hubbs-error-messages [control]="control" [label]="labels.label"></hubbs-error-messages>
		</div>
		<div *ngIf="standalone" class="d-flex">
			<label class="me-2 user-select-none" [for]="name + randomId">{{ labels.label }}</label>
			<div class="form-check form-switch" *ngIf="control.enabled">
				<input class="form-check-input"
					   [name]="name + randomId"
					   [id]="name + randomId" 
					   type="checkbox"
					   [formControl]="control">
			</div>
		</div>
	`,
	styles: [],
})
export class InputSwitchComponent extends BaseComponent implements OnInit {
	@Input() label: string;
	@Input() name: string;
	@Input() control: AbstractControl;
	@Input() standalone: boolean = false;

	labels: { [key: string]: string } = {};
	randomId: string;

	constructor(private translationService: TranslationService) {
		super();

		LanguageService.LanguageChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.setLabels();
		});
		this.randomId = CommonService.GenerateGuid();
	}

	ngOnInit() {
		this.setLabels();
	}
	
	private setLabels(): void {
		this.labels.label = this.translationService.GetTranslation(this.label);
	}

	get validator() {
		if (!this.control.validator) {
			return false;
		}
		const validator = this.control.validator({} as AbstractControl);
		if (validator && validator.required) {
			return true;
		}

		return false;
	}
}
