
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { GenerateTokenRequest } from '@models/generate-token-request.model';
import { TokenType } from '@models/token-type.model';
import { Token } from '@models/token.model';
import { UserStatus } from '@models/user-status.model';
import { AuthenticationService } from '@services/authentication.service';
import { CommonService } from '@services/common.service';
import { TokenService } from '@services/token.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
	UserStatus: UserStatus;
	constructor(private router: Router, private tokenService: TokenService) {}

	canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> | Promise<boolean> {
		return AuthenticationService.HasValidToken().then(
			(result) => {
				if (!result) {
					this.router.navigate(['/public']);
					return false;
				}

				const tokenData = AuthenticationService.TokenData;
				if (tokenData.status === UserStatus.pendingActivation || tokenData.status === UserStatus.pendingPasswordChange) {
					const tokenRequest: GenerateTokenRequest = {
						type: TokenType.changePassword,
						userGuid: tokenData.guid,
					};
					this.tokenService.Generate<Token>(tokenRequest).then(
						(token) => {
							this.router.navigate([`/public/change-password/${token.guid}`]);
						},
						(error) => {}
					);

					localStorage.setItem('returnUrl', route.url.join('/'));
					return false;
				}

				return true;
			},
			() => false
		);
	}
}
