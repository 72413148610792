import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@shared/config/app-config.module';
import { Observable, throwError } from 'rxjs';
import { DestroyableComponent } from '@core/destroyable';
import { AuthenticationService } from './authentication.service';
import { catchError, finalize, map } from 'rxjs/operators';
import { LoadingService } from '@services/loading.service';

@Injectable()
export class InterceptorService extends DestroyableComponent implements HttpInterceptor {
	// @ts-ignore
	private timer: NodeJS.Timeout;
	private loaderTimeout: number = 10000;

	constructor(@Inject(APP_CONFIG) protected config: AppConfig, private loadingService: LoadingService, private readonly authenticationService: AuthenticationService) {
		super();
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token = localStorage.getItem('auth_token');

		const requestingLocalFiles = req.url.indexOf('assets') === 0 || req.url.indexOf('assets') === 1;
		let handledRequest;
		let headers = req.headers;

		if (!requestingLocalFiles) {
			if (token != null) {
				headers = headers.append('Authorization', 'Bearer ' + token);
			}

			headers = headers.append('camelCase', 'true');
			headers = headers.append('app', 'Hubbs portal');

			handledRequest = req.clone({
				url: this.config.apiEndpoint + req.url,
				headers,
			});
		} else {
			handledRequest = req.clone({ headers });
		}

		if (this.timer) {
			clearTimeout(this.timer);
		}

		this.timer = setTimeout(() => this.loadingService.loadingEvent.emit({ key: 'loader_generic_loading_message', show: true }), this.loaderTimeout);

		return next.handle(handledRequest).pipe(
			map((res) => {
				if (res instanceof HttpResponse && res.headers.has('X-Auth-Token')) {
					localStorage.setItem('auth_token', res.headers.get('X-Auth-Token'));
					AuthenticationService.SetTokenData();
				}
				return res;
			}),
			catchError((err: any) => {
				if (err.error) {
					return throwError(err.error);
				}

				if (err instanceof HttpErrorResponse && err.status === 401) {
					this.authenticationService.SignOut();
				}

				return throwError(err);
			}),
			finalize(() => {
				this.loadingService.loadingEvent.emit({ key: 'loader_generic_loading_message', show: false });
				if (this.timer) {
					clearTimeout(this.timer);
				}
			}),
		);
	}
}
