import { NgModule } from '@angular/core';
import { DatepickerComponent } from './datepicker.component';
import { CommonModule } from '@angular/common';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
	declarations: [DatepickerComponent],
	imports: [CommonModule, NgbDatepickerModule],
	exports: [DatepickerComponent],
})
export class DatepickerModule {}
