import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputDateComponent } from './input-date.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ErrorMessagesModule } from '../error-messages/error-messages.module';
import { NgbTimepickerModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { DatepickerModule } from './datepicker/datepicker.module';

@NgModule({
	declarations: [InputDateComponent],
	exports: [InputDateComponent],
	imports: [CommonModule, FormsModule, ReactiveFormsModule, ErrorMessagesModule, NgbTimepickerModule, NgbPopoverModule, DatepickerModule],
})
export class InputDateModule {}
