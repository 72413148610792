import { Injectable, EventEmitter } from '@angular/core';
import { DestroyableComponent } from '@core/destroyable';
import { DialogContent } from '@models/dialog-content.model';
import { DefaultLanguage } from '@translations/translations';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LanguageService } from './language.service';
import { TranslationService } from './translation.service';

@Injectable()
export class ConfirmationService extends DestroyableComponent {
	public showConfirmation: EventEmitter<any> = new EventEmitter();
	public confirmationResult: EventEmitter<boolean> = new EventEmitter();

	private ngConfirmationResult: Subject<void> = new Subject();

	activeLanguage: string;

	constructor(private translationService: TranslationService) {
		super();

		this.activeLanguage = localStorage.getItem('language') ?? DefaultLanguage;
		LanguageService.LanguageChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((language: string) => {
			this.activeLanguage = language;
		});
	}

	public Discard(callback: any): void {
		const confirmation: DialogContent = {
			title: this.translationService.GetTranslation('confirm_modal_discard_title'),
			text: this.translationService.GetTranslation('confirm_modal_discard_description'),
			confirmation: true,
			acceptButtonText: this.translationService.GetTranslation('confirm_modal_discard_continue_button'),
			declineButtonText: this.translationService.GetTranslation('confirm_modal_discard_return_button'),
			centered: true,
			size: 'md',
		};

		this.Dialog(confirmation).then((result) => {
			if (result) {
				callback();
			}
		});
	}

	public Outsmart(callback: any): void {
		const confirmation: DialogContent = {
			title: this.translationService.GetTranslation('confirm_modal_outsmart_title'),
			text: this.translationService.GetTranslation('confirm_modal_outsmart_description'),
			confirmation: true,
			acceptButtonText: this.translationService.GetTranslation('confirm_modal_outsmart_continue_button'),
			declineButtonText: this.translationService.GetTranslation('confirm_modal_outsmart_return_button'),
			centered: true,
			size: 'md',
		};

		this.Dialog(confirmation).then((result) => {
			if (result) {
				callback();
			}
		});
	}

	public CoDetector(callback: any): void {
		const confirmation: DialogContent = {
			title: this.translationService.GetTranslation('confirm_modal_co_detector_title'),
			text: this.translationService.GetTranslation('confirm_modal_co_detector_description'),
			confirmation: true,
			acceptButtonText: this.translationService.GetTranslation('confirm_modal_co_detector_continue_button'),
			declineButtonText: this.translationService.GetTranslation('confirm_modal_co_detector_return_button'),
			centered: true,
			size: 'md',
		};

		this.Dialog(confirmation).then((result) => {
			callback(result);
		});
	}

	public Delete(callback: any): void {
		const confirmation: DialogContent = {
			title: this.translationService.GetTranslation('confirm_modal_delete_title'),
			text: this.translationService.GetTranslation('confirm_modal_delete_description'),
			confirmation: true,
			acceptButtonText: this.translationService.GetTranslation('confirm_modal_delete_continue_button'),
			declineButtonText: this.translationService.GetTranslation('confirm_modal_delete_return_button'),
			centered: true,
			size: 'md',
		};

		this.Dialog(confirmation).then((result) => {
			if (result) {
				callback();
			}
		});
	}
	public DisableTFA(callback: any): void {
		const confirmation: DialogContent = {
			title: this.translationService.GetTranslation('confirm_modal_disabletfa_title'),
			text: this.translationService.GetTranslation('confirm_modal_disabletfa_description'),
			confirmation: true,
			acceptButtonText: this.translationService.GetTranslation('confirm_modal_disabletfa_continue_button'),
			declineButtonText: this.translationService.GetTranslation('confirm_modal_disabletfa_return_button'),
			centered: true,
			size: 'md',
		};

		this.Dialog(confirmation).then((result) => {
			if (result) {
				callback();
			}
		});
	}

	private Dialog(dialogContent: DialogContent): Promise<boolean> {
		return new Promise<boolean>((resolve) => {
			this.confirmationResult
				.pipe(takeUntil(this.ngUnsubscribe))
				.pipe(takeUntil(this.ngConfirmationResult))
				.subscribe((result) => {
					this.ngConfirmationResult.next();
					this.ngConfirmationResult.complete();
					this.ngConfirmationResult = new Subject();
					resolve(result);
				});
			this.showConfirmation.emit(dialogContent);
		});
	}
}
