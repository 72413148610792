import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { DestroyableComponent } from '@core/destroyable';
import { CommonService } from '@services/common.service';
import { LanguageService } from '@services/language.service';
import { TranslationService } from '@services/translation.service';
import { of } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'hubbs-input-password',
	template: `
		<div class="mb-3 form-floating">
			<input
				#input
				[name]="name + randomId"
				[id]="name + randomId"
				type="password"
				[autocomplete]="autocomplete"
				[placeholder]="labels.label"
				class="form-control form-control-lg"
				[formControl]="control"
				[ngClass]="{
					'is-invalid border-danger': control.invalid && (control.dirty || control.touched)
				}"
				[ngbPopover]="passwordRequirements"
				[disablePopover]="!showRequirements"
				placement="right"
				[triggers]="'focus'"
				[autoClose]="'false'"
			/>
			<label [for]="name + randomId">
				{{ labels.label }}
				<span *ngIf="validator && !control.disabled"> *</span>
			</label>
			<hubbs-error-messages [control]="control" [label]="labels.label"></hubbs-error-messages>
			<ng-template #passwordRequirements>
				<div class="p-2">
					<h5 class="mb-2">Wachtwoord voorschriften</h5>
					<table>
						<tr>
							<td><i class="fas fa-check-circle mr-3" [ngClass]="{ 'text-success': control.value.length >= 8 }"></i></td>
							<td>Min. lengte van 8</td>
						</tr>
						<tr>
							<td><i class="fas fa-check-circle mr-3" [ngClass]="{ 'text-success': capitalPattern.test(control.value) }"></i></td>
							<td>Hoofdletter</td>
						</tr>
						<tr>
							<td><i class="fas fa-check-circle mr-3" [ngClass]="{ 'text-success': lowercasePattern.test(control.value) }"></i></td>
							<td>Kleine letter</td>
						</tr>
						<tr>
							<td><i class="fas fa-check-circle mr-3" [ngClass]="{ 'text-success': digitPattern.test(control.value) }"></i></td>
							<td>Getal</td>
						</tr>
						<tr>
							<td><i class="fas fa-check-circle mr-3" [ngClass]="{ 'text-success': specialPattern.test(control.value) }"></i></td>
							<td>Speciaal karakter</td>
						</tr>
					</table>
				</div>
			</ng-template>
		</div>
	`,
	styles: [],
})
export class InputPasswordComponent extends DestroyableComponent implements OnInit {
	private inputField: ElementRef;

	@ViewChild('input', { static: false }) set input(input: ElementRef) {
		if (input) {
			this.inputField = input;
		}
	}

	@Input() label: string;
	@Input() name: string;
	@Input() placeholder: string;
	@Input() focus: boolean;
	@Input() control: AbstractControl;
	@Input() showRequirements: false;
	@Input() autocomplete: string = 'off';

	labels: { [key: string]: string } = {};
	randomId: string;

	capitalPattern: RegExp = new RegExp('(?=.*?[A-Z])');
	lowercasePattern: RegExp = new RegExp('(?=.*?[a-z])');
	digitPattern: RegExp = new RegExp('(?=.*?[0-9])');
	specialPattern: RegExp = new RegExp('(?=.*?[#?!@$%^&*-])');

	constructor(private translationService: TranslationService) {
		super();

		LanguageService.LanguageChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.setLabels();
		});
		this.randomId = CommonService.GenerateGuid();
	}

	ngOnInit() {
		this.setLabels();

		if (this.focus) {
			of(null)
				.pipe(takeUntil(this.ngUnsubscribe), delay(1))
				.subscribe(() => {
					if (!this.inputField?.nativeElement) {
						return;
					}

					this.inputField.nativeElement.focus();
				});
		}
	}

	public setFocus(): void {
		this.inputField.nativeElement.focus();
	}

	private setLabels(): void {
		this.labels.label = this.translationService.GetTranslation(this.label);
		this.labels.placeholder = this.translationService.GetTranslation(this.placeholder);

		this.labels.password_requirements_length = this.translationService.GetTranslation('password_requirements_length');
		this.labels.password_requirements_capital = this.translationService.GetTranslation('password_requirements_capital');
		this.labels.password_requirements_lowercase = this.translationService.GetTranslation('password_requirements_lowercase');
		this.labels.password_requirements_digit = this.translationService.GetTranslation('password_requirements_digit');
		this.labels.password_requirements_specialCharacter = this.translationService.GetTranslation('password_requirements_specialCharacter');
	}

	get validator() {
		if (!this.control.validator) {
			return false;
		}
		const validator = this.control.validator({} as AbstractControl);
		if (validator && validator.required) {
			return true;
		}

		return false;
	}
}
