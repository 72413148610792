import { Component, Input, HostBinding } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DestroyableComponent } from '@core/destroyable';
import { TranslationService } from '@services/translation.service';

@Component({
	selector: 'hubbs-error-messages',
	template: ` <small *ngIf="errorMessage !== null">{{ errorMessage }}</small> `,
})
export class ErrorMessagesComponent extends DestroyableComponent {
	@Input() label: string;
	@Input() control: UntypedFormControl;

	@HostBinding() classList: string = 'invalid-feedback w-100 mt-0';

	constructor(private translationService: TranslationService) {
		super();
	}

	get errorMessage() {
		for (const propertyName in this.control.errors) {
			if (this.control.errors.hasOwnProperty(propertyName)) {
				let translation = this.translationService.GetTranslation('validation_message_' + propertyName);
				translation = translation.replace('{0}', this.label.toLowerCase());

				return translation.charAt(0).toUpperCase() + translation.slice(1);
			}
		}
		return null;
	}
}
