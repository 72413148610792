import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { DestroyableComponent } from '@core/destroyable';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { SyncOutSmartRequest } from '@models/sync-out-smart-request.model';

@Injectable()
export class OutSmartService extends DestroyableComponent {
	constructor(private http: HttpClient) {
		super();
	}

	Sync(syncRequest: SyncOutSmartRequest): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			this.http
				.post<void>(`out-smart/sync`, syncRequest)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error)
				);
		});
	}
}
