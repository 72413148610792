import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextComponent } from './input-text.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ErrorMessagesModule } from '../error-messages/error-messages.module';

@NgModule({
	declarations: [InputTextComponent],
	exports: [InputTextComponent],
	imports: [CommonModule, FormsModule, ReactiveFormsModule, ErrorMessagesModule],
})
export class InputTextModule {}
