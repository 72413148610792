import { Component, Input, EventEmitter, Output, ViewChild, AfterViewInit } from '@angular/core';
import { NgbInputDatepicker, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'hubbs-datepicker',
	templateUrl: 'datepicker.component.html',
})
export class DatepickerComponent implements AfterViewInit {
	@Input() selectedDate: NgbDateStruct;
	@Output() selectDate: EventEmitter<NgbDateStruct> = new EventEmitter<NgbDateStruct>();
	@ViewChild('datePicker') datePicker: NgbInputDatepicker;

	ngAfterViewInit(): void {
		this.datePicker.autoClose = false;
		this.datePicker.toggle();
	}
}
