<ng-template #modalContent let-modal>
	<div class="modal-header">
		<h4 ngbAutofocus class="modal-title" id="modal-title">{{ 'image_upload_modal_title' | translate: activeLanguage }}</h4>
		<button type="button" class="btn btn-link p-0 text-secondary" aria-describedby="modal-title" (click)="close()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body px-3 text-center">							
		<div class="my-3">
			<input type="file" #fileInput accept="image/*" class="d-none" (change)="fileSelected($event, fileInput)" />
			<button type="button" (click)="fileInput.click()" class="btn btn-hubbs-red"><i class="fas fa-upload"></i> {{ 'image_upload_modal_upload_button' | translate: activeLanguage }}</button>
		</div>
		<small>{{ 'image_upload_modal_description' | translate: activeLanguage }}</small>
	</div>
</ng-template>
