import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {DestroyableComponent} from '@core/destroyable';
import {CommonService} from '@services/common.service';
import {LanguageService} from '@services/language.service';
import {TranslationService} from '@services/translation.service';
import {of} from 'rxjs';
import {delay, takeUntil} from 'rxjs/operators';

@Component({
	selector: 'hubbs-input-textarea',
	template: `
		<div class="form-floating" [class.mb-3]="control.enabled">
			<textarea
				#input
				[name]="name + randomId"
				[id]="name + randomId"
				[placeholder]="labels.label"
				class="form-control form-control-lg h-100 resize-none"
				[formControl]="control"
				[ngClass]="{
					'is-invalid border-danger': control.invalid && (control.dirty || control.touched)
				}"
				[rows]="rows"
				[style]="scrollable ? 'overflow-y: scroll' : 'overflow: hidden'"
				(keydown.enter)="enableEnterNewLine ?? checkLength($event)"
			></textarea>
			<hubbs-error-messages [control]="control" [label]="labels.label"></hubbs-error-messages>
			<label [for]="name + randomId">
				{{ labels.label }}
				<span *ngIf="validator && !control.disabled"> *</span>
			</label>
		</div>
	`,
	styles: [],
})
export class InputTextareaComponent extends DestroyableComponent implements OnInit {
	private inputField: ElementRef;

	@ViewChild('textarea', {static: false}) set input(input: ElementRef) {
		if (input) {
			this.inputField = input;
		}
	}

	@Input() label: string;
	@Input() name: string;
	@Input() placeholder: string;
	@Input() focus: boolean;
	@Input() control: AbstractControl;
	@Input() rows: number = 10;
	@Input() enableEnterNewLine: boolean = false;
	@Input() scrollable: boolean = false;

	labels: { [key: string]: string } = {};
	randomId: string;

	constructor(private translationService: TranslationService) {
		super();

		LanguageService.LanguageChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.setLabels();
		});
		this.randomId = CommonService.GenerateGuid();
	}

	ngOnInit() {
		this.setLabels();

		if (this.focus) {
			of(null)
				.pipe(takeUntil(this.ngUnsubscribe), delay(1))
				.subscribe(() => {
					if (!this.inputField?.nativeElement) {
						return;
					}

					this.inputField.nativeElement.focus();
				});
		}
	}

	private setLabels(): void {
		this.labels.label = this.translationService.GetTranslation(this.label);
		this.labels.placeholder = this.translationService.GetTranslation(this.placeholder);
	}

	public setFocus(): void {
		this.inputField.nativeElement.focus();
	}

	get validator() {
		if (!this.control.validator) {
			return false;
		}
		const validator = this.control.validator({} as AbstractControl);
		if (validator && validator.required) {
			return true;
		}

		return false;
	}
	
	public checkLength(event: KeyboardEvent) {
		const textarea = event.target as HTMLTextAreaElement;
		const numberOfLines = textarea.value.split("\n").length;
		
		return (numberOfLines < this.rows);
	}
}
