import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DestroyableComponent } from '@core/destroyable';
import { GenerateTokenRequest } from '@models/generate-token-request.model';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class TokenService extends DestroyableComponent {
	constructor(private http: HttpClient) {
		super();
	}

	Generate<T>(generateTokenRequest: GenerateTokenRequest): Promise<T> {
		return new Promise<T>((resolve, reject) => {
			this.http
				.post<T>(`tokens`, generateTokenRequest)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error)
				);
		});
	}

	public ValidateToken<T>(tokenGuid: string): Promise<T> {
		return new Promise<T>((resolve, reject) => {
			this.http
				.get<T>(`tokens/${tokenGuid}`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result) => resolve(result),
					(error) => reject(error)
				);
		});
	}
}
