<input 
		type="hidden" 
		class="custom-date-picker" 
		ngbDatepicker 
		#datePicker="ngbDatepicker" 
		(dateSelect)="selectDate.emit($event)" 
		[startDate]="selectedDate" 
		[restoreFocus]="null"
		[maxDate]="selectedDate ? { year: selectedDate.year + 20, month: selectedDate.month, day: selectedDate.day } : null"
/>
