import { Component } from '@angular/core';
import { WindowSize } from '@models/window-size.model';
import { BreakpointService } from '@services/breakpoint.service';
import { LanguageService } from '@services/language.service';
import { DefaultLanguage } from '@translations/translations';
import { takeUntil } from 'rxjs/operators';
import { DestroyableComponent } from './destroyable';
import { SortDirection } from '@models/sort-direction.model';

@Component({ selector: 'hubbs-base-component', template: `` })
export abstract class BaseComponent extends DestroyableComponent {
	activeLanguage: string;
	dirty: boolean = false;
	activeWindowSize: WindowSize;
	WindowSize: any = WindowSize;

	constructor() {
		super();

		this.activeLanguage = localStorage.getItem('language') ?? DefaultLanguage;
		LanguageService.LanguageChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((language: string) => {
			this.activeLanguage = language;
		});

		this.activeWindowSize = BreakpointService.CurrentWindowSize;
		BreakpointService.WindowSizeChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.activeWindowSize = BreakpointService.CurrentWindowSize;
		});
	}
}
